import {
    ArrowBackIcon,
    Box,
    Button,
    IModalProps,
    IButtonProps,
    IconButton,
    Modal as ModalNB,
    Text
} from "native-base";
import React, {memo} from "react";

import Actionsheet, {Props as ActionsheetProps} from "../Actionsheet";

import {MODAL_BACKDROP_ZINDEX} from "@/admin/constant";
import useMobileView from "@/client/hooks/useMobileView";

export type Props = {
    isOpen: boolean;
    children: React.ReactNode;
    title: string;
    footer?: React.ReactNode;
    isLoading?: boolean;
    actionSheetProps?: Partial<ActionsheetProps>;
    size?: IModalProps["size"];
    btnTextCancel?: string | null;
    btnTextOk?: string | null;
    btnColorScheme?: IButtonProps["colorScheme"];
    onClose?: () => void;
    onSubmit?: () => void;
};

const Modal: React.FC<Props> = ({
    isOpen,
    children,
    footer,
    title,
    isLoading,
    actionSheetProps,
    size: sizeProps,
    btnTextCancel = "Cancel",
    btnTextOk = "Save",
    btnColorScheme,
    onClose,
    onSubmit
}) => {
    const isMobile = useMobileView();

    if (isMobile) {
        return (
            <Actionsheet
                isOpen={isOpen}
                fullHeight
                hideDragIndicator
                onClose={onClose}
                {...actionSheetProps}
            >
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    p={4}
                    borderBottomWidth="4px"
                    borderBottomStyle="solid"
                    borderColor="blueGray.100"
                    backgroundColor="white"
                >
                    <IconButton
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        colorScheme="blueGray"
                        onPress={onClose}
                        icon={<ArrowBackIcon />}
                    />
                    <Text>{title}</Text>
                    {onSubmit && (
                        <Button
                            variant="ghost"
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            btnColorScheme={btnColorScheme}
                            onPress={onSubmit}
                        >
                            {btnTextOk}
                        </Button>
                    )}
                </Box>
                <Box pb={5} width="100%" maxW="100%">
                    {children}
                </Box>
            </Actionsheet>
        );
    }

    const size = isMobile ? "xl" : "md";

    const Footer = (
        <ModalNB.Footer backgroundColor="white" px={6} shadow="10">
            {onClose && (
                <Button variant="ghost" onPress={onClose}>
                    {btnTextCancel}
                </Button>
            )}
            {onSubmit && (
                <Button
                    ml={typeof onClose === "function" ? 2 : undefined}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    colorScheme={btnColorScheme}
                    onPress={onSubmit}
                >
                    {btnTextOk}
                </Button>
            )}
        </ModalNB.Footer>
    );

    return (
        <ModalNB
            isOpen={isOpen}
            onClose={onClose}
            size={sizeProps ?? size}
            _overlay={{style: {zIndex: MODAL_BACKDROP_ZINDEX}}}
        >
            <ModalNB.Content testID="modal-content" backgroundColor="white" borderRadius={20}>
                {title && (
                    <ModalNB.Header
                        backgroundColor="white"
                        px={6}
                        pt={6}
                        pb={0}
                        borderBottomWidth={0}
                        _text={{fontSize: "xl"}}
                    >
                        {title}
                    </ModalNB.Header>
                )}
                {onClose && (
                    <ModalNB.CloseButton borderRadius="50%" _hover={{bgColor: "blueGray.100"}} />
                )}
                <ModalNB.Body px={6}>{children}</ModalNB.Body>
                {footer ? (
                    <ModalNB.Footer backgroundColor="white" px={6} shadow="10">
                        {footer}
                    </ModalNB.Footer>
                ) : (typeof onSubmit === "function" || typeof onClose === "function") &&
                  (btnTextCancel || btnTextOk) ? (
                    Footer
                ) : null}
            </ModalNB.Content>
        </ModalNB>
    );
};

export default memo(Modal);
