import classNames from "classnames";
import React, {FC, useState, ReactNode, useEffect} from "react";

import BannerRenderer from "./BannerRenderer";

export interface Props {
    actionRenderer?: ReactNode;
    alertClass?: string;
    onClose?: (show: boolean) => void;
}
const Banner: FC<Props> = ({children, actionRenderer, onClose, alertClass = "alert-info"}) => {
    const [hide, setHide] = useState(false);
    const [animate, setAnimate] = useState(true);

    function handleCloseError() {
        setAnimate(true);
        setTimeout(() => {
            setHide(true);
            onClose?.(false);
        }, 500);
    }

    useEffect(() => {
        setTimeout(() => {
            setAnimate(false);
        }, 1500);
    }, []);

    const classesClose = classNames("btn btn-link btn-close", {
        "mg-r-15": !!actionRenderer
    });

    if (hide) {
        return null;
    }

    return (
        <BannerRenderer>
            <div
                className={`banner alert ${alertClass} ${
                    animate ? "alert-inactive" : "alert-active"
                }`}
                role="alert"
            >
                <div className="container">
                    <div className="alert-body">
                        <div className="content">{children}</div>
                        <div className="action alert-right-side">
                            <button
                                type="button"
                                className={classesClose}
                                onClick={handleCloseError}
                                data-dismiss="alert"
                                aria-label="Close popup"
                            >
                                Close
                            </button>
                            {actionRenderer}
                        </div>
                    </div>
                </div>
            </div>
        </BannerRenderer>
    );
};

export default Banner;
