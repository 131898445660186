import {MessageRule} from "@hosttools/frontend/models/messageRule";

export interface FetchMessageRulesPayload {
    listingID?: string;
    listingGroupID?: string;
}

export async function fetchMessageRules(payload?: FetchMessageRulesPayload) {
    const listingID = payload?.listingID;
    const listingGroupID = payload?.listingGroupID;

    let url = "/getMessageRules";
    if (listingGroupID) {
        url += `/group/${listingGroupID}`;
    } else if (listingID) {
        url += `/${listingID}`;
    }

    const res = await fetch(url);

    if (res.ok) {
        const data: MessageRule[] = await res.json();
        return data;
    }
}
