import {extendTheme} from "native-base";
import colors from "@hosttools/frontend/theme/customColor";
import shadows from "@hosttools/frontend/theme/shadows";

import {components} from "./components";
import {fonts} from "./font";

const theme = extendTheme<HostToolsTheme>({
    ...components,
    ...fonts,
    colors,
    shadows
} as HostToolsTheme);

export default theme;
