/* eslint-disable global-require */
import {useEffect} from "react";

import useNewDesign from "./useNewDesign";

function useGlobalCss() {
    const isNewUIEnabled = useNewDesign();

    useEffect(() => {
        if (isNewUIEnabled) {
            require("@/client/css/global.css");
            require("@/client/css/nativebase.scss");
        }
    }, [isNewUIEnabled]);
}

export default useGlobalCss;
