import {ListingGroup} from "@hosttools/frontend/models/listingGroup";
import {HStack, Text, VStack} from "native-base";
import React, {memo} from "react";

import ItemLink from "../Header/MenuItemLink";

import {getListingGroupUrl} from "./util";

type Props = {
    listingGroups: ListingGroup[];
    showAllListing: boolean;
};

const ListingGroupsMenu: React.FC<Props> = ({listingGroups, showAllListing}) => {
    return (
        <>
            <Text fontWeight="semibold" mb={3}>
                Listing Group
            </Text>
            <VStack space={2}>
                {showAllListing && (
                    <ItemLink link={getListingGroupUrl("all")} type="internal">
                        <Text size="sm" color="blueGray.500">
                            All listings
                        </Text>
                    </ItemLink>
                )}
                {listingGroups.map(listingGroup => {
                    return (
                        <ItemLink
                            key={listingGroup._id}
                            link={getListingGroupUrl(listingGroup._id)}
                            type="internal"
                        >
                            <HStack>
                                <Text size="sm" color="blueGray.500" isTruncated numberOfLines={2}>
                                    {listingGroup.name}
                                </Text>
                                <Text size="sm" color="blueGray.500">
                                    {`(${
                                        "uniqueMessageRulesCount" in listingGroup
                                            ? listingGroup.uniqueMessageRulesCount
                                            : ""
                                    })`}
                                </Text>
                            </HStack>
                        </ItemLink>
                    );
                })}
            </VStack>
        </>
    );
};

export default memo(ListingGroupsMenu);
