import React, {
    FC,
    ComponentType,
    lazy,
    Suspense,
    useContext,
    memo,
    useMemo,
    useCallback
} from "react";
import {BrowserRouter, Redirect, Route, Switch, RouteProps} from "react-router-dom";
import {ErrorBoundary} from "@sentry/react";
import {Toaster} from "react-hot-toast";
import FullStory from "react-fullstory";
import {FiAlertTriangle, FiCheck} from "react-icons/fi";
import {NativeBaseProvider, View} from "native-base";
import LinearGradient from "react-native-web-linear-gradient";
import {FrontendProvider, UserContext} from "@hosttools/frontend";
import {RootThemeProvider} from "css-vars-hook";

import {AppProvider} from "../client/provider/AppProvider";

import Header from "./components/Header";
import MessagesNotification from "./components/MessagesNotification";
import ErrorProvider from "./providers/ErrorProvider";
import PrivateRoute from "./components/PrivateRoute";
import theme from "./theme/theme";
import {INBOX_PATH, MESSAGING_PATH, MULTICALENDAR_PATH, PRICING_PATH} from "./constant";

import CSSVarsProvider from "@/client/provider/CSSVarsProvider";
import GlobalCss from "@/client/components/GlobalCss";

const ORG_ID = __IS_BETA__ ? "o-1HJ52A-na1" : "o-1HC3P9-na1";

const MultiCalendar = lazy(() => import("./components/MultiCalendar"));
const Turnovers = lazy(() => import("./components/Turnovers"));
const Footer = lazy(() => import("./components/Footer"));
const Billing = lazy(() => import("./components/Billing"));
const Messaging = lazy(() => import("./components/Messaging"));
const Pricing = lazy(() => import("./components/Pricing"));
const Settings = lazy(() => import("./components/Settings"));
const Inbox = lazy(() => import("./components/Inbox"));
const Register = lazy(() => import("../client/components/Register"));
const Forgot = lazy(() => import("../client/components/Forgot"));
const Login = lazy(() => import("../client/components/Login"));
const Reset = lazy(() => import("../client/components/Reset"));
const Reason = lazy(() => import("./components/Reason"));
const Users = lazy(() => import("./components/Users"));
const QuickGuide = lazy(() => import("../client/features/QuickGuide"));

const WaitingComponent = <P extends Record<string, unknown>>(Component: ComponentType<P>) => {
    return useCallback(
        (props: P) => (
            <Suspense fallback={<div />}>
                <Component {...props} />
            </Suspense>
        ),
        [Component]
    );
};

interface PublicRouteProps extends RouteProps {
    component: ComponentType;
    redirect?: boolean;
}

const config = {
    dependencies: {
        "linear-gradient": LinearGradient
    }
};

const PublicRoute: FC<PublicRouteProps> = ({component: Component, redirect, ...rest}) => {
    const {isAuthenticated} = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated === true && redirect) {
                    return <Redirect to={{pathname: "/"}} />;
                }
                return <Component {...(props as PublicRouteProps["component"]["propTypes"])} />;
            }}
        />
    );
};

const App: FC = () => {
    return (
        <ErrorBoundary fallback={<div>An error has occurred</div>} showDialog={__IS_BETA__}>
            <FrontendProvider baseUrl="">
                <ErrorProvider>
                    <AppProvider>
                        <BrowserRouter>
                            <RootThemeProvider theme={{}}>
                                <NativeBaseProvider theme={theme} config={config}>
                                    <CSSVarsProvider>
                                        <GlobalCss />
                                        {!__DEV__ && <FullStory org={ORG_ID} />}
                                        <div className="az-minimal">
                                            <View
                                                display="flex"
                                                flexDir="column"
                                                height="100vh"
                                                maxH="100vh"
                                                overflow="auto"
                                            >
                                                <Header />
                                                <div className="errors-container">
                                                    <div className="container">
                                                        <Toaster
                                                            containerStyle={{
                                                                position: "absolute",
                                                                inset: 0
                                                            }}
                                                            toastOptions={{
                                                                success: {
                                                                    icon: <FiCheck />,
                                                                    className: "alert",
                                                                    style: {
                                                                        color: "#1f5c01",
                                                                        backgroundColor: "#d8efcc",
                                                                        borderColor: "#c8e9b8"
                                                                    }
                                                                },
                                                                error: {
                                                                    icon: <FiAlertTriangle />,
                                                                    className: "alert",
                                                                    style: {
                                                                        color: "#721c24",
                                                                        backgroundColor: "#f8d7da",
                                                                        borderColor: "#f5c6cb"
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Switch>
                                                    <PrivateRoute
                                                        exact
                                                        path={MULTICALENDAR_PATH}
                                                        component={WaitingComponent(MultiCalendar)}
                                                    />
                                                    <PrivateRoute
                                                        path={INBOX_PATH}
                                                        component={WaitingComponent(Inbox)}
                                                        permission={useMemo(
                                                            () => ["inbox", "edit"],
                                                            []
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path={MESSAGING_PATH}
                                                        component={WaitingComponent(Messaging)}
                                                        permission={useMemo(
                                                            () => ["messageRules", "edit"],
                                                            []
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path={PRICING_PATH}
                                                        component={WaitingComponent(Pricing)}
                                                        permission={useMemo(
                                                            () => ["pricing", "edit"],
                                                            []
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/settings"
                                                        component={WaitingComponent(Settings)}
                                                    />
                                                    <PrivateRoute
                                                        path="/billing"
                                                        component={WaitingComponent(Billing)}
                                                        permission={useMemo(
                                                            () => ["billing", "edit"],
                                                            []
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/users"
                                                        component={WaitingComponent(Users)}
                                                        permission={useMemo(
                                                            () => ["userManagement", "edit"],
                                                            []
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/quick-guide"
                                                        component={WaitingComponent(QuickGuide)}
                                                    />

                                                    <PublicRoute
                                                        path="/login"
                                                        redirect
                                                        component={WaitingComponent(Login)}
                                                    />

                                                    {(true || !__IS_BETA__ || __IS_LOCAL__) && (
                                                        <PublicRoute
                                                            path="/register"
                                                            redirect
                                                            component={WaitingComponent(Register)}
                                                        />
                                                    )}
                                                    <PublicRoute
                                                        path="/forgot"
                                                        component={WaitingComponent(Forgot)}
                                                    />
                                                    <PublicRoute
                                                        path="/reset/:resetPasswordToken"
                                                        component={WaitingComponent(Reset)}
                                                    />

                                                    <PublicRoute
                                                        path="/reason/:id"
                                                        component={WaitingComponent(Reason)}
                                                    />

                                                    <PublicRoute
                                                        path="/turnovers/:accessCode"
                                                        component={WaitingComponent(Turnovers)}
                                                    />
                                                    <Redirect to="/" />
                                                </Switch>
                                                <MessagesNotification />
                                                <Suspense fallback={<div />}>
                                                    <Footer />
                                                </Suspense>
                                            </View>
                                        </div>
                                    </CSSVarsProvider>
                                </NativeBaseProvider>
                            </RootThemeProvider>
                        </BrowserRouter>
                    </AppProvider>
                </ErrorProvider>
            </FrontendProvider>
        </ErrorBoundary>
    );
};

export default memo(App);
