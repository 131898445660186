import React, {memo} from "react";
import {Text} from "native-base";

const SeamCredential: React.FC = () => {
    return (
        <Text size="sm" color="blueGray.700">
            Each smart lock linked to an enabled listing in Host Tools,
            <strong> will incur a $3/month fee.</strong>&nbsp;
            <Text color="blue.600">
                <a
                    href="https://help.hosttools.com/en/articles/8160685-how-much-do-smart-locks-cost"
                    target="_blank"
                    rel="noreferrer"
                >
                    Read more.
                </a>
            </Text>
        </Text>
    );
};

export default memo(SeamCredential);
