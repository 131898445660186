import React, {FC, ComponentType, useContext, memo, useCallback} from "react";
import {Redirect, Route, RouteProps, RouteComponentProps} from "react-router-dom";
import {UserContext} from "@hosttools/frontend";
import {PermissionKey, PermissionValue} from "@hosttools/core/models/user";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import Loader from "../Loader";
import Errors from "../Errors/Errors";
import ModalConfirm from "../ModalConfirm";

import {AppContext} from "@/client/provider/AppProvider";

interface Props extends RouteProps {
    permission?: [PermissionKey, PermissionValue];
    component: ComponentType;
}

const queryClient = new QueryClient();

const PrivateRoute: FC<Props> = ({component: Component, permission, ...rest}) => {
    const {confirmModal} = useContext(AppContext);
    const {isAuthenticated, isLoading, permissions} = useContext(UserContext);
    const handleRender = useCallback(
        (props: RouteComponentProps) => {
            if (isAuthenticated === true && !isLoading) {
                const mainElem = (
                    <>
                        <Errors key={props.location.pathname} />
                        <Component {...(props as Props["component"]["propTypes"])} />
                    </>
                );

                if (permission) {
                    const permissionKey = permission[0];
                    const permissionValue = permission[1];
                    if (permissions[permissionKey]?.[permissionValue]) {
                        return mainElem;
                    }
                    return <Redirect to={{pathname: "/"}} />;
                }
                return mainElem;
            }
            if (!isAuthenticated && !isLoading) {
                return <Redirect to={{pathname: "/login"}} />;
            }

            return (
                <div className="az-signin-wrapper">
                    <Loader />
                </div>
            );
        },
        [permission, Component, isLoading, permissions, isAuthenticated]
    );

    return (
        <QueryClientProvider client={queryClient}>
            <Route {...rest} render={handleRender} />
            {confirmModal && <ModalConfirm {...confirmModal} />}
        </QueryClientProvider>
    );
};

export default memo(PrivateRoute);
