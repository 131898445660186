import getSymbolFromCurrency from "currency-symbol-map";

import {DEFAULT_TIMEZONE, yearInDays} from "../../constant";
import {ListingDocument, ListingGroupDocument, LockDocument} from "../../generated/models";
import {Channel} from "../model/channel";

import getChannelLabel from "./getChannelLabel";

export function getListingName<T extends {airbnbName?: string; nickname?: string}>(listing: T) {
    return listing.nickname || listing.airbnbName || "";
}

export function getLabelPriceSource(priceSource: ListingDocument["priceSource"]) {
    if (!priceSource) {
        return "Amount";
    }
    if (priceSource === "Amount" || priceSource === "PriceLabs") {
        return priceSource;
    }
    return getChannelLabel(priceSource as Channel);
}

export const getListingTimezone = <T extends {airbnbTimeZone?: string}>(listing: T) =>
    listing.airbnbTimeZone || DEFAULT_TIMEZONE;

export const isProPlan = (
    listing: {
        _id: ListingDocument["_id"] | string;
        pricingEnabled?: ListingDocument["pricingEnabled"];
        syncAvailability?: ListingDocument["syncAvailability"];
    },
    locks: {listingIDs?: LockDocument["listingIDs"] | string[]}[],
    listingGroups: {listingIDs: ListingGroupDocument["listingIDs"] | string[]}[]
) => {
    const isPricing = !!listing.pricingEnabled;
    const isAvailability = !!listing.syncAvailability;
    const hasLock = locks.some(
        lock =>
            lock.listingIDs?.length &&
            lock.listingIDs.some(listingID => listing._id.toString() === listingID.toString())
    );
    const isTurnover = listingGroups.some(listingGroup =>
        listingGroup.listingIDs.some(
            listingID => listingID && listing._id.toString() === listingID.toString()
        )
    );
    const isPro = isPricing || isAvailability || hasLock || isTurnover;
    return {isPro, isPricing, isAvailability, hasLock, isTurnover};
};

export const getCalendarExportCode = <T extends {calendarExportCode?: string}>(
    listing: T,
    isBeta: boolean
) => {
    if (!listing.calendarExportCode) {
        return "";
    }
    // eslint-disable-next-line no-undef
    if (isBeta) {
        return `https://beta.hosttools.com/ical/${listing.calendarExportCode}`;
    }

    return `https://app.hosttools.com/ical/${listing.calendarExportCode}`;
};

export function getCurrencySymbol(listingCurrency = "USD") {
    return getSymbolFromCurrency(listingCurrency) || "$";
}

export function getBookingWindowText(listing: {bookingWindow: ListingDocument["bookingWindow"]}) {
    if (listing.bookingWindow === 0) {
        return "2 years";
    }
    if (listing.bookingWindow === yearInDays) {
        return "1 year";
    }
    if (listing.bookingWindow === 547) {
        return "1.5 years";
    }
    return `${listing.bookingWindow} days`;
}
