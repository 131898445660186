import {
    HStack,
    VStack,
    Alert as AlertNB,
    IAlertProps,
    Text,
    IconButton,
    CloseIcon,
    Box
} from "native-base";
import React, {memo} from "react";

export type Props = {
    title?: string;
    children?: React.ReactNode;
    status?: "info" | "success" | "warning" | "error";
    onClose?: () => void;
} & Omit<IAlertProps, "colorScheme">;

const Alert: React.FC<Props> = ({children, title, status = "info", onClose, ...restAlert}) => {
    return (
        <AlertNB status={status} colorScheme={status} {...restAlert} p={0} overflow="hidden">
            <HStack width="100%">
                <Box width="4px" bg={`${status}.700`} />
                <VStack space={2} flexShrink={1} w="100%" p={3}>
                    <HStack
                        flexShrink={1}
                        space={2}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <HStack flexShrink={1} space={2}>
                            <AlertNB.Icon mt={1} />
                            <Box display="flex" flexDir="column" flexShrink={1} flexGrow={1}>
                                {title && (
                                    <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                                        {title}
                                    </Text>
                                )}
                                {children && (
                                    <Text fontSize="sm" fontWeight="400">
                                        {children}
                                    </Text>
                                )}
                            </Box>
                        </HStack>
                        {onClose && (
                            <IconButton
                                variant="unstyled"
                                _focus={{
                                    borderWidth: 0
                                }}
                                icon={<CloseIcon size="3" />}
                                _icon={{
                                    color: "coolGray.600"
                                }}
                                onPress={onClose}
                            />
                        )}
                    </HStack>
                </VStack>
            </HStack>
        </AlertNB>
    );
};

export default memo(Alert);
