import React, {type FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import {filterVisibleListings} from "../../utils/listings";
import {Listing} from "../../models/listing";
import ListingService from "../../services/listing";
import UserService from "../../services/user";
import {User, UserRaw} from "../../models/user";
import HTTPContext from "../contexts/HTTPContext";
import {defaultValue, UserContext, UserContextType} from "../contexts/UserContext";

const UserProvider: FC = ({children}) => {
    const http = useContext(HTTPContext);
    const [value, setValue] = useState<UserContextType>(defaultValue);

    const updateUser = useCallback(async () => {
        const listingService = new ListingService(http);
        const userService = new UserService(http);

        try {
            const [user, listings = [], seamToken] = await Promise.all([
                userService.fetchUser(),
                listingService.fetchAllListings(),
                userService.fetchSeamClientToken()
            ]);

            const visibleListings = filterVisibleListings<Listing>(listings);

            setValue(prev => ({
                ...prev,
                isLoading: false,
                isAuthenticated: true,
                user,
                listings,
                visibleListings,
                listingGroups: user.listingGroups,
                locks: user.locks,
                permissions: user.permissions,
                seamToken
            }));
        } catch {
            setValue(prev => ({
                ...prev,
                isLoading: false
            }));
        }
    }, [http]);

    useEffect(() => {
        updateUser();
    }, [updateUser]);

    const setUser = useCallback((userProps: Partial<UserRaw>) => {
        setValue(prev => {
            const nextUser = new User({...prev.user.raw, ...userProps} as UserRaw);
            return {
                ...prev,
                user: nextUser,
                permissions: nextUser.permissions
            };
        });
    }, []);

    const logout = useCallback(async () => {
        await http.get("/logout");
        setValue(prev => ({
            ...prev,
            ...defaultValue,
            isLoading: false
        }));
    }, [http]);

    return (
        <UserContext.Provider
            value={useMemo(() => {
                return {
                    ...value,
                    setUser,
                    setState: setValue,
                    updateUser,
                    logout
                };
            }, [value, setUser, updateUser, logout])}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
