import {FormControl, ITextProps, Text} from "native-base";
import React, {memo} from "react";

import PopoverInfo from "../PopoverInfo";

export type Props = {
    label?: string;
    children?: React.ReactNode;
    tooltip?: React.ReactNode;
    textProps?: ITextProps;
};

const CustomLabel: React.FC<Props> = ({label, tooltip, children, textProps}) => {
    return (
        <FormControl.Label display="flex" flexDir="row" alignItems="center">
            <Text size="sm" color="blueGray.400" {...textProps}>
                {label || children}
            </Text>
            {tooltip && (
                <>
                    &nbsp;
                    <PopoverInfo label={tooltip} />
                </>
            )}
        </FormControl.Label>
    );
};

export default memo(CustomLabel);
