import {AxiosInstance, AxiosRequestConfig} from "axios";
import {Pagination} from "@hosttools/core/shared/model/pagination";
import qs from "qs";

import {Reservation, ReservationRaw, ReservationPost} from "../../models/reservation";

export interface ReservationPostPayload {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    preferredLocale: string;
    listingID: string;
    startDate: string;
    endDate: string;
    reservationID?: string;
    checkInTime: number;
    checkOutTime: number;
    notes?: string;
    hostPayout?: number;
    guestPrice?: number;
}

export interface ReservationsSearchOptions {
    unread?: 1 | 0;
    keyword?: string;
    offset: number;
    limit: number;
    sortBy: string;
}

class ReservationService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async fetchReservation(reservationID: string) {
        const url = `/reservation/${reservationID}`;
        const {data} = await this.http.get<ReservationRaw>(url);
        return new Reservation(data);
    }

    async fetchReservations(
        filter?: ReservationsSearchOptions,
        config?: AxiosRequestConfig
    ): Promise<Pagination<Reservation>> {
        // skip empty string, not sure if there is a better way to handle this
        if (typeof filter?.keyword === "string" && !filter.keyword) {
            delete filter.keyword;
        }
        const strFilters = filter ? `${qs.stringify(filter, {skipNulls: true})}` : "";
        const url = `/reservations${strFilters ? `?${strFilters}` : ""}`;
        const {data} = await this.http.get<Pagination<ReservationRaw>>(url, config);

        return {
            ...data,
            docs: data.docs.map(elem => new Reservation(elem))
        };
    }

    async fetchReservationPosts(
        reservationID: string,
        params: {
            limit: number;
            offset: number;
        },
        config?: AxiosRequestConfig
    ) {
        const {data} = await this.http.get<Pagination<ReservationPost>>(
            `/reservation/posts/${reservationID}?${qs.stringify(params)}`,
            config
        );
        return data;
    }

    async fetchThread(reservationID: string, nextPageID?: string, config?: AxiosRequestConfig) {
        const {data} = await this.http.get(
            `/getThread/${reservationID}${nextPageID ? `?nextPageID=${nextPageID}` : ""}`,
            config
        );

        return data;
    }

    async updatePosts(reservationID: string) {
        const url = `/updatePosts/${reservationID}`;
        const res = await this.http.get(url);

        return res.data;
    }

    async setRead(reservationID: string) {
        await this.http.post(`/setRead/${reservationID}`);
    }

    async saveReservation(payload: ReservationPostPayload) {
        const url = "/setReservationDetails";
        const {
            firstName,
            lastName,
            email,
            phone,
            preferredLocale,
            startDate,
            endDate,
            reservationID,
            listingID,
            checkInTime,
            checkOutTime,
            notes,
            guestPrice,
            hostPayout
        } = payload;

        const response = await this.http.post(url, {
            source: "internal",
            reservationID,
            listingID,
            startDate,
            endDate,
            guestPrice,
            hostPayout,
            custom: {
                firstName,
                lastName,
                email,
                phone,
                checkInTime,
                checkOutTime,
                notes,
                ...(preferredLocale !== "default" && {preferredLocale})
            }
        });

        return response.data;
    }

    async cancelReservation(reservationID: string) {
        const url = "/cancelReservation";

        const {data} = await this.http.post(url, {
            reservationID
        });

        return data;
    }
}

export default ReservationService;
