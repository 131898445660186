import {
    Actionsheet as ActionsheetNB,
    Box,
    Center,
    IActionsheetProps,
    IActionsheetContentProps,
    Pressable
} from "native-base";
import React, {memo, useEffect, useMemo, useRef} from "react";

import {MODAL_BACKDROP_ZINDEX} from "@/admin/constant";

export type Props = {
    children: React.ReactNode;
    contentProps?: IActionsheetContentProps;
    fullHeight?: boolean;
} & IActionsheetProps;

const Actionsheet: React.FC<Props> = ({
    children,
    contentProps,
    isOpen,
    fullHeight = true,
    hideDragIndicator = true,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const propsFullHeight = useMemo(
        () =>
            fullHeight
                ? {maxH: "100vh", minH: "100vh", borderTopRightRadius: 0, borderTopLeftRadius: 0}
                : undefined,
        [fullHeight]
    );

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (ref.current && isOpen) {
            timeoutId = setTimeout(() => {
                const overlayElement = ref.current?.parentElement?.parentElement?.parentElement;
                if (overlayElement) {
                    overlayElement.style.zIndex = `${MODAL_BACKDROP_ZINDEX}`;
                }
            });
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isOpen]);

    return (
        <ActionsheetNB ref={ref} isOpen={isOpen} hideDragIndicator {...props}>
            <ActionsheetNB.Content
                maxH="95vh"
                minH="95vh"
                bgColor="white"
                overflow="auto"
                p={0}
                {...propsFullHeight}
                {...contentProps}
            >
                {!hideDragIndicator && (
                    <Pressable onPress={props.onClose} width="100%" p={2} bgColor="white">
                        <Center>
                            <Box
                                width="35px"
                                height="4px"
                                borderRadius="20px"
                                bgColor="blueGray.200"
                            />
                        </Center>
                    </Pressable>
                )}
                {children}
            </ActionsheetNB.Content>
        </ActionsheetNB>
    );
};

export default memo(Actionsheet);
