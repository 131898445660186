import {
    Image,
    Modal,
    Text,
    VStack,
    ChevronRightIcon,
    HStack,
    Button,
    ChevronLeftIcon,
    Pressable
} from "native-base";
import React, {memo, useMemo, useCallback, useContext, useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import gravatar from "gravatar";
import {UserContext, usePrevious} from "@hosttools/frontend";

import Avatar from "../Avatar";
import ListingsMenu from "../NavMessaging/ListingsMenu";
import MessasingMenu from "../NavMessaging/MessagingMenu";

import MenuItemMobile from "./MenuItemMobile";
import MenuProfile from "./MenuProfile";
import AvatarProfile from "./AvatarProfile";

import logoBlack from "@/admin/img/logo-black.svg";
import {INBOX_URL, MULTICALENDAR_PATH} from "@/admin/constant";

type Props = {
    isOpen: boolean;
    onHide: () => void;
    onAddAccount: () => void;
    onSupport: () => void;
};

type MenuDisplay = "messaging" | "pricing" | "main" | "profile";

const logoSource = {uri: logoBlack};

const ModalMenuMobile: React.FC<Props> = ({isOpen, onHide, onAddAccount, onSupport}) => {
    const {
        user: {fullName, username},
        visibleListings,
        listingGroups
    } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const prevPathName = usePrevious(location.pathname);
    const [menuDisplay, setMenuDisplay] = useState<MenuDisplay>("main");

    const userImage = useMemo(
        () => gravatar.url(username, {s: "120", r: "pg", d: "mp"}),
        [username]
    );

    const userImageUri = useMemo(() => ({uri: userImage}), [userImage]);

    const handleClickCalendar = useCallback(() => {
        history.push(MULTICALENDAR_PATH);
        onHide();
    }, [history, onHide]);

    const handleClickInbox = useCallback(() => {
        history.push(INBOX_URL);
        onHide();
    }, [history, onHide]);

    const handleClickPricing = useCallback(() => {
        setMenuDisplay("pricing");
    }, []);

    const handleClickMessaging = useCallback(() => {
        setMenuDisplay("messaging");
    }, []);

    const handleClickProfile = useCallback(() => {
        setMenuDisplay("profile");
    }, []);

    const handleBack = useCallback(() => {
        setMenuDisplay("main");
    }, []);

    useEffect(() => {
        if (prevPathName !== location.pathname) {
            onHide();
        }
    }, [location.pathname, onHide, prevPathName]);

    return (
        <Modal isOpen={isOpen} onClose={onHide} size="xl">
            <Modal.Content maxH="600px" marginBottom="auto" marginTop={10}>
                <Modal.CloseButton />
                <Modal.Header>
                    {menuDisplay === "main" && (
                        <Image source={logoSource} height={22} width={110} alt="Host Tools Logo" />
                    )}
                    {menuDisplay !== "main" && (
                        <Button
                            leftIcon={<ChevronLeftIcon />}
                            variant="ghost"
                            onPress={handleBack}
                            alignSelf="flex-start"
                            colorScheme={menuDisplay === "profile" ? "blueGray" : "primary"}
                            p={0}
                        >
                            {menuDisplay === "messaging" && "Messaging"}
                            {menuDisplay === "pricing" && "Pricing"}
                            {menuDisplay === "profile" && <AvatarProfile />}
                        </Button>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {menuDisplay === "main" && (
                        <VStack space={6}>
                            <MenuItemMobile onPress={handleClickCalendar}>Calendar</MenuItemMobile>
                            <MenuItemMobile onPress={handleClickInbox}>Inbox</MenuItemMobile>
                            <MenuItemMobile onPress={handleClickMessaging} hasIcon>
                                Messaging
                            </MenuItemMobile>
                            <MenuItemMobile onPress={handleClickPricing} hasIcon>
                                Pricing
                            </MenuItemMobile>
                            <Pressable
                                flexDir="row"
                                alignItems="center"
                                justifyContent="space-between"
                                bg="blueGray.100"
                                borderRadius={4}
                                p={2}
                                onPress={handleClickProfile}
                            >
                                <HStack space={2} alignItems="center">
                                    <Avatar
                                        name={fullName}
                                        source={userImageUri}
                                        size="sm"
                                        bg="cyan.600"
                                        borderWidth={1}
                                        borderStyle="solid"
                                        borderColor="white"
                                    />
                                    <Text fontWeight="bold">{fullName}</Text>
                                </HStack>
                                <ChevronRightIcon color="blueGray.400" />
                            </Pressable>
                        </VStack>
                    )}
                    {menuDisplay === "pricing" && (
                        <ListingsMenu listings={visibleListings} to="pricing" />
                    )}
                    {menuDisplay === "messaging" && (
                        <MessasingMenu
                            listingGroups={listingGroups}
                            visibleListings={visibleListings}
                        />
                    )}
                    {menuDisplay === "profile" && (
                        <MenuProfile onAddAccount={onAddAccount} onSupport={onSupport} />
                    )}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default memo(ModalMenuMobile);
