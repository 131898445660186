import {useCallback, useEffect, useRef} from "react";

function useTimeout(callback: () => void, delay: number | null) {
    const savedCallbackRef = useRef(callback);
    const idRef = useRef<number>();

    useEffect(() => {
        savedCallbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        return () => {
            if (typeof idRef.current === "number") {
                clearTimeout(idRef.current);
            }
        };
    }, [delay]);

    return useCallback(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }
        const id = window.setTimeout(() => savedCallbackRef.current(), delay);
        idRef.current = id;
    }, [delay]);
}

export default useTimeout;
