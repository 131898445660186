import {UserContext} from "@hosttools/frontend";
import React, {memo, useContext, ComponentType, useMemo} from "react";
import {
    CogIcon,
    UserIcon,
    CreditCardIcon,
    SupportIcon,
    LogoutIcon,
    Props as IIconProps
} from "@hosttools/frontend/react/components/Icons";
import {AddIcon, Divider, HStack, QuestionIcon, Text, VStack} from "native-base";

import MenuItemLink, {MenuItemType} from "./MenuItemLink";
import AvatarProfile from "./AvatarProfile";

import useMobileView from "@/client/hooks/useMobileView";

type Props = {
    onAddAccount: () => void;
    onSupport: () => void;
};

type MenuItem = MenuItemType & {
    label: string;
    Icon: ComponentType<IIconProps>;
};

const MenuProfile: React.FC<Props> = ({onAddAccount, onSupport}) => {
    const {
        user: {permissions}
    } = useContext(UserContext);
    const isMobile = useMobileView();

    const menuDisplay: MenuItem[] = useMemo(
        () => [
            ...(permissions.userManagement?.edit
                ? [
                      {
                          Icon: UserIcon,
                          label: "Users",
                          link: "/settings/user",
                          type: "internal"
                      } as MenuItem
                  ]
                : []),
            {Icon: CogIcon, label: "Settings", link: "/settings", type: "internal"},
            ...(permissions.billing?.edit
                ? [
                      {
                          Icon: CreditCardIcon,
                          label: "Billing",
                          link: "/settings/billing",
                          type: "internal"
                      } as MenuItem
                  ]
                : []),
            {
                Icon: QuestionIcon,
                label: "FAQ",
                link: "https://help.hosttools.com/",
                type: "external",
                isExternal: true
            },
            {
                Icon: AddIcon,
                label: "Link Account",
                link: onAddAccount,
                type: "function"
            },
            {Icon: SupportIcon, label: "Support", link: onSupport, type: "function"},
            {Icon: LogoutIcon, label: "Logout", link: "/logout", type: "external"}
        ],
        [onAddAccount, onSupport, permissions.billing?.edit, permissions.userManagement?.edit]
    );

    return (
        <VStack space={2}>
            {!isMobile && (
                <>
                    <AvatarProfile />
                    <Divider bg="blueGray.200" />
                </>
            )}
            {menuDisplay.map(menu => {
                const MenuNode = (
                    <HStack space={2} alignItems="center">
                        <menu.Icon color="blueGray.400" />
                        <Text size="sm">{menu.label}</Text>
                    </HStack>
                );
                return (
                    <React.Fragment key={menu.label}>
                        {menu.label === "Logout" && <Divider bg="blueGray.200" />}
                        {menu.type === "internal" && (
                            <MenuItemLink link={menu.link} type="internal">
                                {MenuNode}
                            </MenuItemLink>
                        )}
                        {menu.type === "external" && (
                            <MenuItemLink
                                link={menu.link}
                                type="external"
                                isExternal={menu.isExternal}
                            >
                                {MenuNode}
                            </MenuItemLink>
                        )}
                        {menu.type === "function" && (
                            <MenuItemLink link={menu.link} type="function">
                                {MenuNode}
                            </MenuItemLink>
                        )}
                    </React.Fragment>
                );
            })}
        </VStack>
    );
};

export default memo(MenuProfile);
