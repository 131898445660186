export const REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const CALENDAR_SLOT_WIDTH = 80;

export const CALENDAR_SLOT_WIDTH_NATIVE_BASE = 120;

export const CALENDAR_SLOT_WIDTH_MOBILE = 60;

export const REGEX_URL =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/i;

export const AIRBNB_URL = `https://www.airbnb.com/oauth2/auth?client_id=${AIRBNB_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    AIRBNB_REDIRECT_URL
)}&scope=${AIRBNB_SCOPE}&state=`;

export const base64ImagePrefix = "data:image/png;base64,";

export const MULTICALENDAR_PATH = "/";
export const INBOX_PATH = "/inbox/:reservationID?";
export const MESSAGING_PATH = "/messaging/:listingID/:listingGroupID?";
export const PRICING_PATH = "/pricing/:listingID";
export const INBOX_URL = "/inbox";

export const MODAL_BACKDROP_ZINDEX = 1040;
export const POPOVER_BACKDROP_ZINDEX = 1040;
export const TOOLTIP_BACKDROP_ZINDEX = 1070;

export const MAX_NUMBER_OF_NEXT_YEARS = 2;
export const BACKDROP_FIXED_ZINDEX = 1030;
export const DRAWER_ZINDEX = 1035;

export const CONTAINER_WIDTH = 1440;
export const TOP_MENU_HEIGHT_DESKTOP = 60;
export const FOOTER_HEIGHT = 35;
export const TOP_NEW_UI_BANNER_HEIGHT = __IS_BETA__ ? 24 : 0;
