import {AxiosInstance} from "axios";

class AccountService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async forceUpdate(id: string) {
        await this.http.post("/forceUpdate", {
            _id: id
        });
    }

    async deleteAccount(id: string) {
        await this.http.post("/deleteAccount", {
            accountID: id
        });
    }
}

export default AccountService;
