import React, {FC, memo} from "react";
import {Text, HStack, VStack, ITextProps} from "native-base";
import {buildChannel} from "@hosttools/frontend/models/channel";
import type {Listing} from "@hosttools/frontend/models/listing";

import ListingThumbnail from "../ListingThumbnail";

type Size = "xs" | "sm" | "md";

type Props = {
    listing: Listing;
    size?: Size;
    color?: ITextProps["color"];
    includeChannel?: boolean;
};

const logoSizesMap = {
    xs: {
        logoSize: 16,
        textSize: "xs"
    },
    sm: {
        logoSize: 16,
        textSize: "sm"
    },
    md: {
        logoSize: 20,
        textSize: "md"
    }
} satisfies Record<
    Size,
    {
        logoSize: number;
        textSize: ITextProps["size"];
    }
>;

const ListingSlat: FC<Props> = ({listing, size = "md", color, includeChannel = true}) => {
    const channel = buildChannel(listing.source);
    const styles = logoSizesMap[size];
    return (
        <HStack space={2} alignItems="center">
            <ListingThumbnail listing={listing} size={size} />
            <VStack flexShrink="unset" space={2}>
                <Text isTruncated size={styles.textSize} numberOfLines={2} color={color}>
                    {listing.name}
                </Text>
                {includeChannel && <channel.Logo width={styles.logoSize} />}
            </VStack>
        </HStack>
    );
};

export default memo(ListingSlat);
