import React, {memo, useCallback} from "react";
import {FormControl} from "native-base";

import Label from "../Label";
import Input from "../Input";

import type {CredentialsType, Errors} from "@/admin/components/ModalAddAccount/types";

type Props = {
    credentials: CredentialsType;
    errors?: Errors;
    onChangeCredentials: (field: "apiKey", value: string) => void;
};

const HoufyCredential: React.FC<Props> = ({credentials, errors, onChangeCredentials}) => {
    const handleChangeAPIKey = useCallback(
        (text: string) => {
            onChangeCredentials("apiKey", text);
        },
        [onChangeCredentials]
    );

    return (
        <FormControl isInvalid={!!errors?.apiKey}>
            <Label tooltip="Enter your Houfy API key.">API Access Token</Label>
            <Input
                testID="apiKey"
                id="apiKey"
                placeholder="API Access Token..."
                name="apiKey"
                autoComplete="off"
                value={credentials.apiKey}
                onChangeText={handleChangeAPIKey}
                isRequired
            />
            <FormControl.ErrorMessage>{errors?.apiKey}</FormControl.ErrorMessage>
        </FormControl>
    );
};

export default memo(HoufyCredential);
