import {useEffect, useState} from "react";
import {io, Socket} from "socket.io-client";

export const useWebSocket = (url: string, init?: boolean) => {
    const [ws, setWS] = useState<Socket | undefined>();

    useEffect(() => {
        if (init) {
            const socket = io(url, {
                withCredentials: true,
                transports: ["websocket"]
                // ...options,
            });
            setWS(socket);

            socket.on("connect_error", err => {
                if (__DEV__) {
                    console.warn(`connect_error due to ${err.message}`);
                }
            });
            socket.on("connect", () => {
                if (__DEV__) {
                    console.log("websocket connected!");
                }
            });
            socket.on("disconnect", () => {
                if (__DEV__) {
                    console.log("websocket disconnected :(");
                }
            });
        }
    }, [init, url]);

    useEffect(() => {
        return () => {
            if (ws) {
                ws.disconnect();
                ws.close();
            }
        };
    }, [ws]);

    return ws;
};
