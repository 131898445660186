import {AccountType} from "@hosttools/core/constant";
import {HStack, Icon, Text, VStack} from "native-base";
import React, {memo, useMemo} from "react";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";

import {ReactComponent as SmartLockSolidSquareIcon} from "@/admin/img/smart-lock-solid-square.svg";

export type Props = {
    channel: AccountType;
};

const ChannelHeader: React.FC<Props> = ({channel}) => {
    const {label, LogoCircle} = useChannel(channel);

    const {title, subTitle} = useMemo(() => {
        let title = `${label} Account`;
        let subTitle = `Connect your account with ${label} credentials`;

        if (channel === "Airbnb") {
            subTitle = "Connect your Airbnb account to Host Tools.";
        }

        if (channel === "Booking") {
            title = "Booking.com Account";
            subTitle = "Connect host tools to booking.com";
        }

        if (channel === "Houfy") {
            subTitle = "Link your account with your Houfy Access Token";
        }

        if (channel === "Seam") {
            title = "Smart Lock Account";
            subTitle = "Connect your smart lock account to host tools.";
        }

        return {
            title,
            subTitle
        };
    }, [channel, label]);

    return (
        <HStack space={4} alignItems="center">
            <Icon
                rounded="full"
                as={channel === "Seam" ? SmartLockSolidSquareIcon : LogoCircle}
                size={9}
            />
            <VStack>
                <Text size="xl" fontWeight="semibold">
                    {title}
                </Text>
                <Text size="xs" color="blueGray.700">
                    {subTitle}
                </Text>
            </VStack>
        </HStack>
    );
};

export default memo(ChannelHeader);
