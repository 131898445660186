import React, {memo, useCallback} from "react";
import {Text, Popover as PopoverNB, IPopoverProps, Pressable, IBoxProps} from "native-base";
import {IPopoverContentProps} from "native-base/lib/typescript/components/composites/Popover/types";

import {POPOVER_BACKDROP_ZINDEX} from "@/admin/constant";

export type Props = {
    content: React.ReactNode;
    children?: React.ReactNode;
    contentProps?: IPopoverContentProps;
    bodyProps?: IBoxProps;
    hasArrow?: boolean;
} & Omit<IPopoverProps, "children" | "_overlay" | "trigger">;

const Popover: React.FC<Props> = ({
    content,
    children,
    contentProps,
    hasArrow,
    bodyProps,
    ...props
}) => {
    const trigger: IPopoverProps["trigger"] = useCallback(
        triggerProps => {
            return (
                <Pressable {...triggerProps} maxW="100%">
                    {children}
                </Pressable>
            );
        },
        [children]
    );

    return (
        <PopoverNB
            trigger={trigger}
            placement="top"
            _overlay={{style: {zIndex: POPOVER_BACKDROP_ZINDEX}}}
            {...props}
        >
            <PopoverNB.Content maxW="300px" {...contentProps}>
                {hasArrow && (
                    <PopoverNB.Arrow
                        bgColor="blueGray.800"
                        borderColor="blueGray.800"
                        style={{top: null}}
                    />
                )}
                <PopoverNB.Body bgColor="blueGray.800" {...bodyProps}>
                    {typeof content === "string" ? (
                        <Text size="xs" color="white" fontWeight={400} textAlign="center">
                            {content}
                        </Text>
                    ) : (
                        content
                    )}
                </PopoverNB.Body>
            </PopoverNB.Content>
        </PopoverNB>
    );
};

export default memo(Popover);
