import {AirbnbThreadObj} from "@hosttools/core/channels/airbnb/types";
import {BookingThreadObj} from "@hosttools/core/channels/booking/types";
import {useCallback, useContext, useEffect, useRef, useState} from "react";

import useAbortController from "../useAbortController";
import {ReservationService} from "../../../services";
import HTTPContext from "../../contexts/HTTPContext";

export type ChannelsThreadObj = AirbnbThreadObj | BookingThreadObj;

const useChannelThread = (reservationID?: string, offset?: number) => {
    const [thread, setThread] = useState<
        {thread: ChannelsThreadObj; offset?: number; reservationID: string} | undefined
    >();
    const threadRef = useRef<{nextPageID?: string}>();
    const abortRef = useAbortController(reservationID);
    const http = useContext(HTTPContext);
    const currentRequest = useRef<Promise<any>>();

    const refetch = useCallback(
        async (nextPageID?: string) => {
            if (!reservationID) {
                return;
            }

            const reservationService = new ReservationService(http);
            return reservationService.fetchThread(reservationID, nextPageID, {
                signal: abortRef.current?.signal
            });
        },
        [reservationID, http, abortRef]
    );

    // those effects will run in order
    useEffect(() => {
        setThread(undefined);
        threadRef.current = undefined;
        currentRequest.current = undefined;
    }, [reservationID]);

    useEffect(() => {
        let active = true;

        (async () => {
            if (reservationID) {
                if (currentRequest.current) {
                    await currentRequest.current;
                }

                // caching this might end up problem as waiting above `await currentRequest.current`
                const nextPageID =
                    typeof offset === "number" &&
                    offset > 0 &&
                    threadRef.current &&
                    "nextPageID" in threadRef.current
                        ? threadRef.current.nextPageID
                        : undefined;

                currentRequest.current = refetch(nextPageID)
                    .then(thread => {
                        currentRequest.current = undefined;
                        if (active) {
                            setThread({
                                thread,
                                offset,
                                reservationID
                            });
                        }
                        threadRef.current = thread;
                    })
                    .catch(() => {
                        currentRequest.current = undefined;
                    });
            }
        })();

        return () => {
            active = false;
        };
    }, [reservationID, offset, refetch]);

    return {thread, refetch};
};

export default useChannelThread;
