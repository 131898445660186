import React, {memo, useEffect} from "react";
import {useRootTheme} from "css-vars-hook";
import usePathColors from "@hosttools/frontend/react/hooks/usePathColors";

export type Props = {
    children: React.ReactNode;
};

const CSSVarsProvider: React.FC<Props> = ({children}) => {
    const flatColors = usePathColors();
    const {setTheme} = useRootTheme();

    useEffect(() => {
        setTheme(flatColors);
    }, [flatColors, setTheme]);

    return <>{children}</>;
};

export default memo(CSSVarsProvider);
