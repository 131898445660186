import {AxiosRequestConfig, AxiosInstance} from "axios";

import {Timeline} from "../../models/timeline";

export interface DayOfWeek {
    sunday?: boolean;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
}

class TimelineService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async fetchTimelines(reservationID: string, config: AxiosRequestConfig) {
        try {
            const url = `/timelines/${reservationID}`;
            const {data} = await this.http.get<Timeline[]>(url, config);
            return data;
        } catch {
            return undefined;
        }
    }
}

export default TimelineService;
