import {AxiosInstance} from "axios";

import {Listing, sanitizeListing, sanitizeListings} from "../../models/listing";
import {ListingGroup} from "../../models/listingGroup";

class ListingService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async fetchAllListings() {
        const url = "/listings/all";
        const {data} = await this.http.get<Listing[]>(url);

        return sanitizeListings(data);
    }

    async fetchAllListingGroups() {
        const url = "/listingGroups";
        const res = await this.http.get<Record<string, ListingGroup>>(url);

        return res.data;
    }

    async fetchListing(listingID: string) {
        const url = `/getListing/${listingID}`;
        const response = await this.http.get<Listing>(url);
        return sanitizeListing(response.data);
    }

    async save(listing: Partial<Listing>) {
        const url = "/editListingSettings";
        await this.http.post(url, listing);
    }
}

export default ListingService;
