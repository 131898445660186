import {Box, Image, type IBoxProps} from "native-base";
import React, {memo, useMemo} from "react";
import {Listing} from "@hosttools/frontend/models/listing";
import {HomeIcon} from "@hosttools/frontend/react/components/Icons";

type Size = "xs" | "sm" | "md";

export type Props = {
    size?: "xs" | "sm" | "md";
    listing: Listing;
} & IBoxProps;

const sizesMap = {
    xs: {
        size: "32px",
        borderRadius: 4
    },
    sm: {
        size: "48px",
        borderRadius: 8
    },
    md: {
        size: "64px",
        borderRadius: 10
    }
} satisfies Record<
    Size,
    {
        size: IBoxProps["size"];
        borderRadius: IBoxProps["borderRadius"];
    }
>;

const ListingThumbnail: React.FC<Props> = ({listing, size = "md", ...rest}) => {
    const thumbnailSource = useMemo(() => {
        let thumbnailUrl = listing.airbnbThumbnailUrl;
        const {linkedListings} = listing;
        if (linkedListings?.length) {
            const childListingThumbnailListing = linkedListings.find(
                (linkedListing: Listing) => !!linkedListing.airbnbThumbnailUrl
            );
            thumbnailUrl = childListingThumbnailListing?.airbnbThumbnailUrl;
        }
        if (thumbnailUrl) {
            return {
                uri: thumbnailUrl
            };
        }
    }, [listing]);

    const sizeImage = sizesMap[size];

    return (
        <Box
            alignItems="center"
            justifyContent="center"
            size={sizeImage.size}
            borderRadius={sizeImage.borderRadius}
            overflow="hidden"
            bg="blueGray.200"
            {...rest}
        >
            {thumbnailSource ? (
                <Image width="full" height="full" source={thumbnailSource} alt={listing.source} />
            ) : (
                <HomeIcon color="blueGray.400" size="md" />
            )}
        </Box>
    );
};

export default memo(ListingThumbnail);
