import React, {memo, useCallback} from "react";
import {Button, FormControl, Link, Text, VStack} from "native-base";

import Label from "../Label";
import Input from "../Input";

import type {CredentialsType, Errors} from "@/admin/components/ModalAddAccount/types";

type Props = {
    credentials: CredentialsType;
    errors?: Errors;
    onChangeCredentials: (field: "propertyID", value: string) => void;
};

const BookingCredential: React.FC<Props> = ({credentials, errors, onChangeCredentials}) => {
    const handleChangePropertyID = useCallback(
        (text: string) => {
            onChangeCredentials("propertyID", text);
        },
        [onChangeCredentials]
    );

    return (
        <VStack space={3}>
            <Text size="sm" color="blueGray.700">
                <b>1.</b> Click the button below to select <b>Host Tools</b> as your{" "}
                <b>Connectivity Provider</b> on Booking.com.
            </Text>
            <Button>
                <Link
                    _text={{
                        color: "white",
                        size: "sm"
                    }}
                    isUnderlined={false}
                    isExternal
                    href="https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1590"
                >
                    Select Connectivity Provider
                </Link>
            </Button>
            <Text size="sm" color="blueGray.700">
                <b>2.</b> Once you have selected <b>Host Tools</b> as your
                <b> Connectivity Provider</b> it can take a few minutes for Booking.com to complete
                the request. You will receive a &quot;Connection activated&quot; message in your
                Booking.com <b>Inbox</b>
                once the activation is complete.
            </Text>
            <Text size="sm" color="blueGray.700">
                <b>3.</b> Once your connection is activated, enter your
                <b> Booking.com Property ID</b> below and click
                <b> Add Account</b>. It can take a few minutes for your account connection to be
                confirmed on Booking.com before it can be added to Host Tools. If you have trouble
                connecting your account, please confirm that Host Tools is your connectivity
                provider on Booking.com, wait a few minutes and try again.
            </Text>
            <FormControl isInvalid={!!errors?.propertyID}>
                <Label tooltip="Enter your Booking.com property ID.">Booking.com Property ID</Label>
                <Input
                    id="propertyID"
                    placeholder="Booking.com Property ID..."
                    name="propertyID"
                    autoComplete="off"
                    value={credentials.propertyID}
                    onChangeText={handleChangePropertyID}
                    isRequired
                />
                <FormControl.ErrorMessage>{errors?.propertyID}</FormControl.ErrorMessage>
            </FormControl>
        </VStack>
    );
};

export default memo(BookingCredential);
