import {AccountType} from "@hosttools/core/constant";
import {ChainIcon, RefreshIcon} from "@hosttools/frontend/react/components/Icons";
import {
    AddIcon,
    Button,
    HStack,
    Icon,
    Image,
    Pressable,
    Stack,
    Text,
    View,
    WarningIcon
} from "native-base";
import React, {memo, useCallback} from "react";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";

import {ConnectedChannels} from "@/admin/components/ModalAddAccount/Channels";

type Props = {
    channel: AccountType;
    connectedChannels: ConnectedChannels;
    disconnectedChannels: ConnectedChannels;
    hideSocial?: boolean;
    onSelectChannel: (channel: AccountType) => void;
};

const ChannelSelection: React.FC<Props> = ({
    channel,
    connectedChannels,
    disconnectedChannels,
    hideSocial,
    onSelectChannel
}) => {
    const numberOfConnected = connectedChannels[channel];
    const numberOfDisconnected = disconnectedChannels[channel];
    console.log(channel, "numberOfDisconnected:", numberOfDisconnected);
    const {LogoCircle, label, logoCircle} = useChannel(channel);

    const handleSelectChannel = useCallback(() => {
        onSelectChannel(channel);
    }, [channel, onSelectChannel]);

    return (
        <View
            p={4}
            flexGrow={1}
            flexShrink={1}
            flexDirection={["row", hideSocial ? "column" : "row"]}
            alignItems="center"
            justifyContent="space-between"
            borderWidth={1}
            borderStyle="solid"
            borderColor="blueGray.200"
            borderRadius={8}
            width={["full", hideSocial ? 40 : "full"]}
        >
            <Pressable
                testID={`${channel}-logo-wrapper`}
                onPress={handleSelectChannel}
                role="presentation"
            >
                <Stack
                    space={4}
                    direction={["row", hideSocial ? "column" : "row"]}
                    justifyContent="center"
                    alignItems="center"
                >
                    {channel === "Seam" ? (
                        <Image source={{uri: logoCircle}} alt={label} width={24} height={8} />
                    ) : (
                        <Icon as={LogoCircle} size={8} />
                    )}
                    <View alignItems={hideSocial ? "center" : "flex-start"}>
                        <Text fontWeight="semibold">{label}</Text>
                        {!!numberOfConnected && !numberOfDisconnected && (
                            <HStack
                                space={1}
                                alignItems="center"
                                bg="blueGray.50"
                                py={0.5}
                                px={1}
                                borderRadius={4}
                                mt={1}
                            >
                                <ChainIcon color="blueGray.400" size={3} />
                                <Text size="sm">+ {connectedChannels[channel]}</Text>
                            </HStack>
                        )}
                        {!!numberOfDisconnected && (
                            <HStack
                                space={1}
                                alignItems="center"
                                bg="error.50"
                                py={0.5}
                                px={1}
                                borderRadius={4}
                            >
                                <WarningIcon color="error.600" size={3} />
                                <Text size="sm" color="error.600">
                                    + {disconnectedChannels[channel]}
                                </Text>
                            </HStack>
                        )}
                    </View>
                </Stack>
            </Pressable>
            <Button
                data-testid={`${channel}-button-connect`}
                variant="ghost"
                size="sm"
                onPress={handleSelectChannel}
                startIcon={numberOfDisconnected ? <RefreshIcon size={3} /> : <AddIcon />}
            >
                {!!numberOfDisconnected && "Fix"}
                {!numberOfDisconnected && `Connect ${numberOfConnected ? "More" : "Acount"}`}
            </Button>
        </View>
    );
};

export default memo(ChannelSelection);
