import React, {memo} from "react";

import useGlobalCss from "@/client/hooks/useGlobalCss";

const GlobalCss: React.FC = () => {
    useGlobalCss();

    return null;
};

export default memo(GlobalCss);
