import React, {useCallback, memo} from "react";
import {AccountType} from "@hosttools/core/constant";
import {FiPlus, FiRefreshCcw} from "react-icons/fi";
import {buildChannel} from "@hosttools/frontend/models/channel";
import {Stack, Text, VStack, View} from "native-base";

import useNewDesign from "@/client/hooks/useNewDesign";
import ChannelSelection from "@/client/components/ModalAddAccount/ChannelSelection";

export type ConnectedChannels = Partial<Record<AccountType, number>>;

export type Props = {
    channels: readonly AccountType[];
    errors: {
        error?: string;
    };
    connectedChannels?: ConnectedChannels;
    disconnectedChannels?: ConnectedChannels;
    hideSocial?: boolean;
    onSelectChannel: (channel: AccountType) => void;
};

const Channels: React.FC<Props> = ({
    channels,
    connectedChannels = {},
    disconnectedChannels = {},
    errors,
    hideSocial,
    onSelectChannel
}) => {
    const isNewUIEnabled = useNewDesign();

    const handleSelectChannel = useCallback(
        channel => () => {
            onSelectChannel(channel);
        },
        [onSelectChannel]
    );

    if (isNewUIEnabled) {
        return (
            <>
                <View mb={4}>
                    {hideSocial ? (
                        <Text mt={[4, 0]} fontWeight="semibold" color="blueGray.700">
                            Choose a channel list
                        </Text>
                    ) : (
                        <VStack space={2}>
                            <Text display={["block", "none"]} fontWeight="bold" size="2xl">
                                Connect Account
                            </Text>
                            <Text size="sm" color="blueGray.500">
                                Host Tool links with channels like Airbnb, Booking.com, and VRBO to
                                automate the management of your listing.
                            </Text>
                            <Text size="sm" color="blueGray.500">
                                Please select the channels you would like to link with Host Tools.
                            </Text>
                        </VStack>
                    )}
                </View>
                <Stack direction={["column", hideSocial ? "row" : "column"]} space={4}>
                    {channels.map(channel => (
                        <ChannelSelection
                            key={channel}
                            channel={channel}
                            connectedChannels={connectedChannels}
                            disconnectedChannels={disconnectedChannels}
                            hideSocial={hideSocial}
                            onSelectChannel={onSelectChannel}
                        />
                    ))}
                </Stack>
            </>
        );
    }

    return (
        <>
            <p>
                Host Tool links with channels like Airbnb, Booking.com, and VRBO to automate the
                management of your listing.
            </p>
            <p className="mg-b-50">
                Please select the channels you would like to link with Host Tools.
            </p>
            {channels.map(channel => {
                const numberOfConnected = connectedChannels[channel];
                const numberOfDisconnected = disconnectedChannels[channel];
                const {logoBrand} = buildChannel(channel);

                return (
                    <div key={channel} className="d-flex justify-content-between pd-y-15">
                        <div
                            data-testid={`${channel}-logo-wrapper`}
                            className="d-flex justify-content-start align-items-center c-pointer"
                            onClick={handleSelectChannel(channel)}
                            onKeyPress={handleSelectChannel(channel)}
                            role="presentation"
                        >
                            <img className="mg-r-10" src={logoBrand} alt={channel} height="30" />
                            {!!numberOfConnected && !numberOfDisconnected && (
                                <div className="badge-outline badge-outline-success">
                                    connected • {connectedChannels[channel]}
                                </div>
                            )}
                            {!!numberOfDisconnected && (
                                <div className="badge-outline badge-outline-danger">
                                    disconnected • {disconnectedChannels[channel]}
                                </div>
                            )}
                        </div>
                        <button
                            data-testid={`${channel}-button-connect`}
                            type="button"
                            className="btn btn-outline-primary pd-y-6"
                            onClick={handleSelectChannel(channel)}
                        >
                            {!!numberOfDisconnected && (
                                <>
                                    <span className="d-flex align-items-center mg-r-10">
                                        <FiRefreshCcw />
                                    </span>
                                    <span>Fix</span>
                                </>
                            )}
                            {!numberOfDisconnected && (
                                <>
                                    <span className="d-flex align-items-center mg-r-10">
                                        <FiPlus />
                                    </span>
                                    &nbsp;
                                    <span>{`Connect ${numberOfConnected ? "more" : ""}`}</span>
                                </>
                            )}
                        </button>
                    </div>
                );
            })}
            {errors.error && <div className="alert alert-danger">{errors.error}</div>}
        </>
    );
};

export default memo(Channels);
