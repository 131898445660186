import React, {memo} from "react";
import {Text, VStack} from "native-base";

const texts = [
    "Host Tools will automatically send messages to your guests or set your listing's prices and availability on your behalf. It will only send messages you tell it to send and will never make any changes to your account without your permission.",
    "All network traffic is encrypted on Host Tools and all data is stored using the latest compliance standards."
];

const HosttoolPolicy: React.FC = () => {
    return (
        <VStack space={4}>
            {texts.map(text => (
                <Text key={text} size="sm" color="blueGray.700">
                    {text}
                </Text>
            ))}
        </VStack>
    );
};

export default memo(HosttoolPolicy);
