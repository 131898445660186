import React, {FC, useMemo} from "react";

import UserWatchChange from "../components/UserWatchChange";
import {FrontendContext} from "../contexts/Frontend";

import WebSocketProvider from "./WebSocketProvider";
import HTTPProvider from "./HTTPProvider";
import UserProvider from "./UserProvider";

interface Props {
    baseUrl: string;
}

const FrontendProvider: FC<Props> = ({baseUrl = "", children}) => {
    return (
        <FrontendContext.Provider value={useMemo(() => ({baseUrl}), [baseUrl])}>
            <HTTPProvider url={baseUrl}>
                <UserProvider>
                    <WebSocketProvider url={baseUrl}>
                        {children}
                        <UserWatchChange />
                    </WebSocketProvider>
                </UserProvider>
            </HTTPProvider>
        </FrontendContext.Provider>
    );
};

export default FrontendProvider;
