import React, {memo} from "react";
import {Carousel} from "react-bootstrap";
import {Carousel as ResponsiveCarousel} from "react-responsive-carousel";
import {Image, Text, VStack, View} from "native-base";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@/client/css/carousel.scss";

import Amanda from "../img/users/Amanda.jpg";
import Adam from "../img/users/Adam.jpg";
import Monica from "../img/users/Monica.jpg";
import Aamna from "../img/users/Aamna.jpg";
import Hilary from "../img/users/Hilary.jpg";
import Caley from "../img/users/Caley.jpg";

import useNewDesign from "@/client/hooks/useNewDesign";

const items = [
    {
        avatarUrl: Amanda,
        name: "Amanda S.",
        title: "Excellent functionality; great value!",
        statement:
            "It was clearly created by a fellow property owner and addresses our biggest concerns without unnecessary fluff. The price is fair, and we feel fortunate to have found it."
    },
    {
        avatarUrl: Adam,
        name: "Adam",
        title: "Host Tools has been a lifesaver!",
        statement:
            "It has saved me countless hours by automating my Airbnb and VRBO messaging. It also helps me keep my listings availability in sync and pricing up to date."
    },
    {
        avatarUrl: Monica,
        name: "Monica-Hisano",
        title: "Never miss another booking opportunity!",
        statement:
            "'Host Tools' gives me the security of knowing that my business is being taken care of 24/7 and is the most efficient and affordable product in the vacation rental hosting market."
    },
    {
        avatarUrl: Aamna,
        name: "Aamna",
        title: "It’s is so easy to use",
        statement: "Love it. It is less expensive as compared to other similar platforms."
    },
    {
        avatarUrl: Hilary,
        name: "Hilary",
        title: "Host Tools is a Rental Property Live Saver!",
        statement:
            "I feel like I have seen it evolve into a critical tool that I use to run my rental properties. I have recommended it to several friends who also use it."
    },
    {
        avatarUrl: Caley,
        name: "Caley",
        title: "Wish I'd Found It Sooner",
        statement:
            "Our guests have gushed about how great our communication is - and we know we were lacking in that area previously."
    }
];

const SocialValidation: React.FC = () => {
    const isNewUIEnabled = useNewDesign();

    if (isNewUIEnabled) {
        return (
            <View maxWidth="full" height="full" bg="white" borderRadius={16} p={6}>
                <ResponsiveCarousel
                    autoPlay
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    showArrows={false}
                    stopOnHover={false}
                >
                    {items.map(({avatarUrl, name, statement, title}) => (
                        <View key={name} height="full" justifyContent="space-between">
                            <VStack space={4}>
                                <Text size="2xl" fontWeight="bold">
                                    &quot;
                                    {title}
                                    &quot;
                                </Text>
                                <Text size="xl" fontWeight="semibold">
                                    {statement}
                                </Text>
                            </VStack>
                            <View
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text size="2xl" fontWeight="bold" color="blue.600">
                                    {name}
                                </Text>
                                <Image
                                    rounded="full"
                                    width={16}
                                    height={16}
                                    source={{uri: avatarUrl}}
                                    alt="profile"
                                />
                            </View>
                        </View>
                    ))}
                </ResponsiveCarousel>
            </View>
        );
    }

    return (
        <Carousel className="custom-carousel" controls={false} indicators interval={2000}>
            {items.map(({avatarUrl, name, statement, title}) => (
                <Carousel.Item key={name}>
                    <div className="d-flex flex-column pd-b-60 mg-x-20 mg-t-20">
                        <h5>
                            &quot;
                            <span dangerouslySetInnerHTML={{__html: title}} />
                            &quot;
                        </h5>
                        <p className="tx-12" dangerouslySetInnerHTML={{__html: statement}} />
                        <img
                            className="rounded-circle mg-b-10 d-none d-md-block"
                            style={{width: 60, height: 60}}
                            src={avatarUrl}
                            alt="profile"
                        />
                        <div className="tx-12 mg-b-10 d-none d-md-block">{name}</div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default memo(SocialValidation);
