import React, {FC, useContext} from "react";

import {useWebSocket} from "../hooks/useWebSocket";
import {UserContext} from "../contexts/UserContext";
import WebSocketContext from "../contexts/WebSocketContext";

interface Props {
    url: string;
}

const WebSocketProvider: FC<Props> = ({url, children}) => {
    const {isAuthenticated} = useContext(UserContext);
    const websocket = useWebSocket(url, isAuthenticated);

    return <WebSocketContext.Provider value={websocket}>{children}</WebSocketContext.Provider>;
};

export default WebSocketProvider;
