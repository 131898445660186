import React, {memo, useCallback} from "react";
import {
    ArrowForwardIcon,
    Center,
    HStack,
    Pressable,
    Progress,
    Skeleton,
    Text,
    VStack,
    View
} from "native-base";
import {useHistory} from "react-router-dom";

import useQuickGuide from "./useQuickGuide";

const QuickGuideButton: React.FC = () => {
    const history = useHistory();

    const {numberCompleted, totalSteps} = useQuickGuide();

    const handleClick = useCallback(() => {
        history.push("/quick-guide");
    }, [history]);

    return (
        <Pressable
            display="flex"
            flexDir="row"
            alignItems="center"
            px={2}
            py={1.5}
            borderRadius={6}
            bgColor="success.600"
            onPress={handleClick}
        >
            <VStack space={2} mr={3} flex={1}>
                {typeof numberCompleted !== "undefined" ? (
                    <>
                        <View
                            display="flex"
                            flexDir="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text size="xs" color="blueGray.50" fontWeight="semibold">
                                Quick Start Guide
                            </Text>
                            <HStack alignItems="baseline">
                                <Text size="xs" color="blueGray.50" fontWeight="semibold">
                                    {numberCompleted}
                                </Text>
                                <Text size="2xs" color="blueGray.50" fontWeight="semibold">
                                    /{totalSteps}
                                </Text>
                            </HStack>
                        </View>
                        <View w={32}>
                            <Progress
                                h={1}
                                value={(numberCompleted / totalSteps) * 100}
                                bg="rgba(255, 255, 255, 0.2)"
                                _filledTrack={{bg: "white"}}
                            />
                        </View>
                    </>
                ) : (
                    <>
                        <Skeleton h={4} my="px" w={32} />
                        <Skeleton h={1} w={32} />
                    </>
                )}
            </VStack>

            <Center
                p={1}
                flexShrink={0}
                flexGrow={0}
                rounded="full"
                background="rgba(240, 253, 244, 0.20)"
            >
                <ArrowForwardIcon size="sm" color="white" />
            </Center>
        </Pressable>
    );
};

export default memo(QuickGuideButton);
