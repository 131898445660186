import React, {FC, useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";

const BannerRenderer: FC = ({children}) => {
    const [element, setElement] = useState<HTMLDivElement | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const bannerContainer = useRef<HTMLElement | null>(null);

    const timeoutAppendElement = useRef<NodeJS.Timeout>();
    const timeoutRemoveBanner = useRef<NodeJS.Timeout>();

    useEffect(() => {
        async function effect() {
            const el = document.createElement("div");
            elementRef.current = el;
            setElement(elementRef.current);

            const bannerElement = document.getElementsByClassName("banner")[0] as HTMLDivElement;
            // // if exist banner, make animation to remove it.
            if (bannerElement) {
                await new Promise(resolve => {
                    timeoutRemoveBanner.current = setTimeout(() => {
                        bannerElement.classList.remove("alert-active");
                        bannerElement.classList.add("alert-inactive");
                        resolve("");
                    }, 1000);
                });
            }

            timeoutAppendElement.current = setTimeout(() => {
                bannerContainer.current = document.getElementById("banner-container");
                if (bannerContainer.current) {
                    bannerContainer.current.innerHTML = "";
                    bannerContainer.current.appendChild(el);
                }
            }, 1000);
        }

        effect();

        return () => {
            if (elementRef.current && bannerContainer.current?.contains(elementRef.current)) {
                bannerContainer.current?.removeChild(elementRef.current);
            }

            if (timeoutAppendElement.current) {
                clearTimeout(timeoutAppendElement.current);
            }

            if (timeoutRemoveBanner.current) {
                clearTimeout(timeoutRemoveBanner.current);
            }
        };
    }, []);

    if (element) {
        return ReactDOM.createPortal(children, element);
    }
    return null;
};

export default BannerRenderer;
