import React, {memo} from "react";
import {FormControl as FormControlNB, IFormControlProps} from "native-base";

export type Props = IFormControlProps;

const FormControl: React.FC<Props> = props => {
    return <FormControlNB zIndex="auto" {...props} />;
};

export default memo(FormControl);
