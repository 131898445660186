import type {AccountType} from "@hosttools/core/constant";

import * as AirbnbIcon from "../assets/airbnb-icon.svg";
import * as BookingIcon from "../assets/booking-icon.svg";
import * as HomeAwayIcon from "../assets/homeaway-icon.svg";
import * as HoufyIcon from "../assets/houfy-icon.svg";
import * as AugustIcon from "../assets/august-icon.svg";
import * as AirbnbBrand from "../assets/airbnb-logo.svg";
import * as BookingBrand from "../assets/booking-logo.svg";
import * as HomeAwayBrand from "../assets/vrbo-logo.svg";
import * as HoufyBrand from "../assets/houfy-logo.svg";
import * as AugustBrand from "../assets/august-logo.svg";
import * as AirbnbIconCircle from "../assets/airbnb-logo-solid-circle.svg";
import * as HomeawayIconCircle from "../assets/homeaway-logo-solid-circle.svg";
import * as BookingIconCircle from "../assets/booking-logo-solid-circle.svg";
import * as HoufyIconCircle from "../assets/houfy-icon-circle.svg";
import * as AugustIconCircle from "../assets/august-logo-solid-circle.svg";
import * as HosttoolLogo from "../assets/logo.svg";
import * as CalendarIcon from "../assets/calendar-icon.svg";
import * as SeamIcon from "../assets/lock.svg";

type FCSVG = typeof AirbnbIcon.ReactComponent;

export interface ChannelObject {
    label: string;
    value: AccountType;
    colorScheme: string;
    logoBackgroundColor?: string;
    logo: string;
    /**
     * @deprecated
     */
    bgClass: string;
    logoBrand: string;
    Logo: FCSVG;
}

const logoMaker = ({default: logo, ReactComponent: Logo}: typeof AirbnbIcon) =>
    typeof logo === "string" ? {logo, Logo} : {logo: "", Logo: logo};

const logoCircleMaker = ({
    default: logoCircle,
    ReactComponent: LogoCircle
}: typeof AirbnbIconCircle) =>
    typeof logoCircle === "string"
        ? {logoCircle, LogoCircle}
        : {logoCircle: "", LogoCircle: logoCircle};

const logoBrandMaker = ({default: urlOrSvg, ReactComponent}: typeof AirbnbIcon) =>
    typeof urlOrSvg === "string"
        ? {logoBrand: urlOrSvg, LogoBrand: ReactComponent}
        : {logoBrand: "", LogoBrand: urlOrSvg};

export const channelInternal = {
    label: "internal",
    value: "internal",
    ...logoMaker(HosttoolLogo),
    ...logoCircleMaker(HosttoolLogo),
    logoBrand: undefined,
    LogoBrand: undefined,
    bgClass: "bg-internal",
    colorScheme: "internal"
} as const;

const channelsMap = {
    internal: channelInternal,
    Airbnb: {
        label: "Airbnb",
        value: "Airbnb",
        ...logoMaker(AirbnbIcon),
        ...logoCircleMaker(AirbnbIconCircle),
        ...logoBrandMaker(AirbnbBrand),
        bgClass: "bg-airbnb",
        colorScheme: "Airbnb"
    },
    Booking: {
        label: "Booking",
        value: "Booking",
        ...logoMaker(BookingIcon),
        ...logoCircleMaker(BookingIconCircle),
        ...logoBrandMaker(BookingBrand),
        logoBackgroundColor: "#0C3B7C",
        bgClass: "bg-booking",
        colorScheme: "Booking"
    },
    HomeAway: {
        label: "VRBO",
        value: "HomeAway",
        ...logoMaker(HomeAwayIcon),
        ...logoCircleMaker(HomeawayIconCircle),
        ...logoBrandMaker(HomeAwayBrand),
        bgClass: "bg-homeaway",
        colorScheme: "HomeAway"
    },
    Houfy: {
        label: "Houfy",
        value: "Houfy",
        ...logoMaker(HoufyIcon),
        ...logoCircleMaker(HoufyIconCircle),
        ...logoBrandMaker(HoufyBrand),
        bgClass: "bg-houfy",
        colorScheme: "Houfy"
    },
    August: {
        label: "August",
        value: "August",
        ...logoMaker(AugustIcon),
        ...logoCircleMaker(AugustIconCircle),
        ...logoBrandMaker(AugustBrand),
        bgClass: "bg-august",
        colorScheme: "red"
    },
    Seam: {
        label: "Seam",
        value: "Seam",
        ...logoMaker(SeamIcon),
        ...logoCircleMaker(SeamIcon),
        ...logoBrandMaker(SeamIcon),
        bgClass: "bg-august",
        colorScheme: "red"
    }
} as const;

export function buildChannel<T extends AccountType | "internal">(
    channel: T
): typeof channelsMap[T] {
    // source imported via iCal will look like this: www.airbnb.com.au
    return (
        channelsMap[channel] ??
        ({
            label: channel,
            value: channel,
            ...logoMaker(CalendarIcon),
            ...logoCircleMaker(CalendarIcon),
            logoBrand: undefined,
            LogoBrand: undefined,
            bgClass: "bg-icalendar",
            colorScheme: "icalendar"
        } as const)
    );
}
