import {V33xTheme} from "native-base";

type Text = V33xTheme["components"]["Text"];
type Badge = V33xTheme["components"]["Badge"];
type Heading = V33xTheme["components"]["Heading"];
type Modal = V33xTheme["components"]["Modal"];
type ErrorMessage = V33xTheme["components"]["FormControlErrorMessage"];
type FormControl = V33xTheme["components"]["FormControl"];

const baseStyle: Text["baseStyle"] = () => {
    return {} as ReturnType<Text["baseStyle"]>;
};

export const components = {
    components: {
        Text: {
            baseStyle,
            defaultProps: {
                color: "blueGray.800",
                size: "md"
            },
            sizes: {
                "4xl": {
                    fontSize: "36px"
                },
                "3xl": {
                    fontSize: "30px",
                    fontWeight: "500"
                },
                "2xl": {
                    fontSize: "24px",
                    fontWeight: "500"
                },
                xl: {
                    fontSize: "20px",
                    fontWeight: "500"
                },
                lg: {
                    fontSize: "18px",
                    fontWeight: "500"
                },
                md: {
                    fontSize: "16px",
                    fontWeight: "500"
                },
                sm: {
                    fontSize: "14px",
                    fontWeight: "500"
                },
                xs: {
                    fontSize: "12px",
                    fontWeight: "500"
                },
                "2xs": {
                    fontSize: "10px",
                    fontWeight: "500"
                }
            }
        } as Text,
        Heading: {
            defaultProps: {
                color: "blueGray.800",
                size: "xl"
            },
            sizes: {
                "4xl": {
                    fontSize: ["60px"]
                },
                "3xl": {
                    fontSize: ["48px"]
                },
                "2xl": {
                    fontSize: ["36px"]
                },
                xl: {
                    fontSize: ["24px", "30px"]
                },
                lg: {
                    fontSize: ["20px", "24px"]
                },
                md: {
                    fontSize: "20px"
                },
                sm: {
                    fontSize: "16px"
                },
                xs: {
                    fontSize: "14px"
                }
            }
        } as Partial<Heading>,
        Button: {
            defaultProps: {}
        },
        Box: {
            defaultProps: {}
        },
        Badge: {
            defaultProps: {
                variant: "subtle",
                colorScheme: "muted",
                borderRadius: "4px",
                py: "2px",
                px: "4px",
                _text: {
                    lineHeight: "normal",
                    size: "xs",
                    isTruncated: true
                },
                _icon: {
                    size: "xs"
                }
            }
        } as Partial<Badge>,
        Modal: {
            sizes: {
                xs: {
                    contentSize: {
                        width: "60%",
                        maxWidth: "280"
                    }
                },
                sm: {
                    contentSize: {
                        width: "65%",
                        maxWidth: "320"
                    }
                },
                md: {
                    contentSize: {
                        width: "75%",
                        maxWidth: "500"
                    }
                },
                lg: {
                    contentSize: {
                        width: "80%",
                        maxWidth: "800"
                    }
                },
                xl: {
                    contentSize: {
                        width: "90%",
                        maxWidth: "1000"
                    }
                },
                full: {
                    contentSize: {
                        width: "100%"
                    }
                }
            }
        } as Partial<Modal>,
        FormControlErrorMessage: {
            defaultProps: {
                _text: {fontSize: "sm", fontWeight: "normal"}
            }
        } as Partial<ErrorMessage>,
        FormControl: {
            defaultProps: {
                zIndex: "auto"
            }
        } as Partial<FormControl>
    }
};
