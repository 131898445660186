import React, {ComponentType, memo} from "react";

import Icon, {Props as IconProps} from "./Icon";

export const iconMaker = (
    reactComponent: React.ComponentType,
    displayName: string
): ComponentType<IconProps> => {
    const IconComponent = (props: IconProps) => {
        return <Icon as={reactComponent} {...props} />;
    };

    IconComponent.displayName = displayName;

    return memo(IconComponent);
};
