import {FC, memo, useEffect, useContext} from "react";

import updateListWS from "../../../utils/updateListWS";
import {filterVisibleListings} from "../../../utils/listings";
import {buildGlobalErrors} from "../../../utils/errors";
import {ListingGroup} from "../../../models/listingGroup";
import {Listing, sanitizeListings} from "../../../models/listing";
import {Lock, sanitizeLock} from "../../../models/lock";
import {UserContext, WebSocketContext} from "../../contexts";
import {useWatchAccount} from "../../hooks/useWatchAccount";
import useWatchUser from "../../hooks/useWatchUser";
import {setListingsIntoAccounts} from "../../../utils/account";

const UserWatchChange: FC = () => {
    const websocket = useContext(WebSocketContext);
    const {user, isAuthenticated, setState} = useContext(UserContext);
    const {accounts} = useWatchAccount(user._id, isAuthenticated);

    useWatchUser();

    useEffect(() => {
        if (!accounts) {
            setState(prev => ({
                ...prev,
                isLoadingAccounts: true
            }));
            return;
        }
        setState(prev => {
            const {user, listings, visibleListings} = prev;
            const nextAccounts = setListingsIntoAccounts(accounts, listings);
            return {
                ...prev,
                isLoadingAccounts: false,
                accounts: nextAccounts,
                errors: buildGlobalErrors(user, accounts, listings, visibleListings)
            };
        });
    }, [accounts, setState]);

    useEffect(() => {
        if (!accounts) {
            return;
        }

        function syncListing(payload: WSPayload<Listing>) {
            setState(prev => {
                if (!prev.listings) {
                    return prev;
                }

                const {user, accounts} = prev;
                let listings = updateListWS(prev.listings, payload);
                listings = sanitizeListings(listings);
                const visibleListings = filterVisibleListings(listings);
                return {
                    ...prev,
                    errors: buildGlobalErrors(user, accounts, listings, visibleListings),
                    listings,
                    visibleListings
                };
            });
        }

        function syncListingGroups(payload: WSPayload<ListingGroup>) {
            setState(prev => {
                const listingGroups: ListingGroup[] = updateListWS(prev.listingGroups, payload);
                const fixedListingGroups = listingGroups.map(elem => {
                    if (!prev.listings) {
                        return elem;
                    }
                    elem.listings = elem.listingIDs.reduce((result, listingID) => {
                        const listing = prev.listings.find(listing => listing._id === listingID);
                        if (listing) {
                            result.push(listing);
                        }
                        return result;
                    }, [] as Listing[]);
                    return elem;
                });
                return {
                    ...prev,
                    listingGroups: fixedListingGroups
                };
            });
        }

        function syncLock(payload: WSPayload<Lock>) {
            setState(prev => {
                const nextLocks = updateListWS(prev.locks, payload);
                return {
                    ...prev,
                    locks: nextLocks.map(sanitizeLock)
                };
            });
        }

        websocket?.on<WSRoom>("listing", syncListing);
        websocket?.on<WSRoom>("listingGroup", syncListingGroups);
        websocket?.on<WSRoom>("lock", syncLock);

        return () => {
            websocket?.off("listing", syncListing);
            websocket?.off("listingGroup", syncListingGroups);
            websocket?.off("lock", syncLock);
        };
    }, [websocket, accounts, setState]);

    return null;
};

export default memo(UserWatchChange);
