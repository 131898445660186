import React, {memo} from "react";
import {Icon as NBIcon, IIconProps, useTheme} from "native-base";
import get from "lodash/get";
import {IColors} from "native-base/lib/typescript/theme/v33x-theme/base/colors";

export type Props = {color?: IColors} & Omit<IIconProps, "color">;

const Icon: React.FC<Props> = ({color, ...props}) => {
    const {colors} = useTheme();
    const colorHex = color ? get(colors, color) : undefined;

    return <NBIcon {...props} fill={colorHex} />;
};

export default memo(Icon);
