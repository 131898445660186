import {FormControl, IFormControlErrorMessageProps} from "native-base";
import React, {memo} from "react";

export type Props = IFormControlErrorMessageProps;

const ErrorMessage: React.FC<Props> = ({children}) => {
    return (
        <FormControl.ErrorMessage _text={{fontSize: "sm", fontWeight: "400"}}>
            {children}
        </FormControl.ErrorMessage>
    );
};

export default memo(ErrorMessage);
