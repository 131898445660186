import type {AccountType} from "@hosttools/core/constant";
import {useMemo} from "react";

import {buildChannel} from "../../../models/channel";

function useChannel(channel: AccountType | "internal") {
    return useMemo(() => buildChannel(channel), [channel]);
}

export default useChannel;
