import {Text, VStack, View} from "native-base";
import React, {memo} from "react";

const AirbnbCredential: React.FC = () => {
    return (
        <>
            <View mb={4}>
                <Text size="xs" color="blueGray.700">
                    After connecting your Airbnb account to Host Tools, some of the Airbnb account
                    settings will change.&nbsp;
                    <Text color="blue.600">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://help.hosttools.com/en/articles/6289414-what-will-change-on-my-airbnb-account-when-i-connect-it-to-host-tools"
                        >
                            Read more.
                        </a>
                    </Text>
                </Text>
            </View>
            <VStack ml={4} space={2}>
                <Text size="sm" color="blueGray.700">
                    <b>Fee settings</b> may change to host-only.
                </Text>
                <Text size="sm" color="blueGray.700">
                    <b>Instant book</b> will be enabled on all listings.
                </Text>
                <Text size="sm" color="blueGray.700">
                    <b>All imported iCals</b> will be removed from Airbnb.
                </Text>
            </VStack>
        </>
    );
};

export default memo(AirbnbCredential);
