import React, {useState, useEffect, useRef} from "react";

import waterDrop from "../sound/waterDrop.mp3";

const useNotifySound = () => {
    const audioRef = useRef(new Audio(waterDrop));
    const [playing, setPlaying] = useState(false);
    const audio = audioRef.current;

    const toggle = () => {
        setPlaying(!playing);
    };

    useEffect(() => {
        if (playing) {
            audio.play().catch(() => {
                setPlaying(false);
            });
        } else {
            audio.pause();
        }
    }, [playing]);

    useEffect(() => {
        audio.addEventListener("ended", handleAudioEnded);
        return () => {
            audio.removeEventListener("ended", handleAudioEnded);
        };
    }, []);

    function handleAudioEnded() {
        setPlaying(false);
    }

    return [playing, toggle] as const;
};

export default useNotifySound;
