import {AxiosInstance, AxiosRequestConfig} from "axios";

import {Message} from "../../models/message";

class MessageService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async fetchMessages(reservationID: string, config?: AxiosRequestConfig) {
        try {
            const url = `/getMessages/${reservationID}`;
            const {data} = await this.http.get<{
                messages: Message[];
            }>(url, config);

            return data.messages;
        } catch {
            return undefined;
        }
    }

    async sendMessage(
        {
            reservationID,
            message,
            uri,
            file,
            filename
        }: {
            reservationID: string;
            message: string;
            uri?: string;
            file?: Blob;
            filename?: string;
        },
        config?: AxiosRequestConfig
    ) {
        const url = "/sendMessage";

        // media part
        if (uri || file) {
            const formData = new FormData();
            formData.append("reservationID", reservationID);
            formData.append("message", message);

            if (uri) {
                const name = filename ?? uri.split("/").pop();
                const match = name && /\.(\w+)$/.exec(name);
                const type = match ? `image/${match[1]}` : "image";
                // it's confusing why it works?
                formData.append("image", {uri, name, type} as any);
            } else if (file) {
                formData.append("image", file, filename);
            }

            const {data} = await this.http.post(url, formData, {
                ...config,
                headers: {"Content-Type": "multipart/form-data"},
                // this fixes to send form for 0.x version
                transformRequest: data => data
            });
            return data;
        }
        const {data} = await this.http.post(
            url,
            {
                reservationID,
                message
            },
            {
                ...config
            }
        );
        return data;
    }

    async getMessage(reservationID: string, messageRuleID: string) {
        const url = `/getMessage/${reservationID}/${messageRuleID}`;
        const {data} = await this.http.get<{
            message: Message | null;
        }>(url);
        return data.message;
    }
}

export default MessageService;
