import {
    getCheckInTime,
    getCheckOutTime,
    getExternalUrl,
    getFullName
} from "@hosttools/core/shared/utils/utils.reservation";
import {
    LegacyFields,
    Reservation as ReservationModel
} from "@hosttools/core/shared/model/reservation";

import {Listing} from "./listing";

export type ReservationRaw = MongooseModel2Client<ReservationModel> &
    Partial<LegacyFields> & {
        post?: Required<MongooseModel2Client<ReservationModel>>["posts"][number];
    };

export type ReservationPost = NonNullable<Required<ReservationRaw>["posts"]>[number] & {
    pending?: boolean;
};

export type ReservationSource = ReservationRaw["source"]; // Exclude<Channel, Channel.August | Channel.Seam>;

export class Reservation {
    raw: ReservationRaw;

    _id: string;

    source: ReservationSource;

    accountID: string;

    firstName: string;

    lastName: string;

    fullName: string;

    createdAt: string | undefined;

    airbnbThreadID?: string;

    thumbnailUrl: string | undefined;

    email: string;

    phone: string;

    preferredLocale: string;

    numberOfGuests?: number;

    nights?: number;

    nightsFormatted: string;

    confirmationCode?: string;

    startDate: string;

    endDate: string;

    listingID: string;

    status: ReservationRaw["status"];

    checkInTime?: number;

    checkOutTime?: number;

    notes: string;

    guestPrice: number;

    hostPayout: number;

    nextScheduledMessageAt: string | undefined;

    lastMessageAt: string | undefined;

    calendarID?: string;

    posts: ReservationRaw["posts"];

    lastPost: ReservationPost | undefined;

    unread: number;

    externalUrl: string;

    statusLabel: string;

    statusClassNames: string;

    statusColor: string;

    statusBgColor: string;

    confirmationDate?: string;

    custom?: ReservationRaw["custom"];

    constructor(data: ReservationRaw) {
        this.raw = data;
        this._id = data._id;
        this.source = data.source;
        this.firstName = getFirstName(data);
        this.lastName = getLastName(data);
        this.fullName = getFullName(data);
        this.email = getEmail(data);
        this.preferredLocale = getPreferredLocale(data);
        this.phone = getPhone(data);
        this.thumbnailUrl = data.airbnbThumbnailUrl;
        this.nights = data.airbnbNights ? Number(data.airbnbNights) : undefined;
        this.nightsFormatted = formatNights(this.nights);
        this.accountID = data.accountID;
        this.createdAt = data.createdAt;
        this.confirmationCode = getConfirmationCode(data);
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.listingID = data.listingID;
        this.status = data.status;
        this.checkInTime = data.custom?.checkInTime;
        this.checkOutTime = data.custom?.checkOutTime;
        this.nextScheduledMessageAt = data.nextScheduledMessageAt;
        this.lastMessageAt = data.airbnbLastMessageAt;
        this.notes = data.custom?.notes ?? "";
        this.numberOfGuests = data.airbnbNumberOfGuests;
        this.calendarID = data.calendarID;
        this.guestPrice = data.guestPrice ?? 0;
        this.hostPayout = data.hostPayout ?? 0;
        this.posts = data.posts;
        this.unread = data.unread ?? 0;
        this.externalUrl = getExternalUrl(data);
        const {label, classNames, color, bgColor} = reservationStatusMap(data.status);
        this.statusLabel = label;
        this.statusClassNames = classNames;
        this.statusColor = color;
        this.statusBgColor = bgColor;
        this.confirmationDate = data.confirmationDate;
        this.custom = data.custom;
        this.lastPost = data.post;
    }
}

export const getCheckInOutTime = (reservation: Reservation, listing: Listing) => {
    return {
        checkInTime: getCheckInTime(reservation, listing),
        checkOutTime: getCheckOutTime(reservation, listing)
    };
};

export function getConfirmationCode(reservation: ReservationRaw) {
    switch (reservation.source) {
        case "Airbnb":
            return reservation.airbnbConfirmationCode;

        case "Booking":
            return reservation.bookingReservationID;

        case "HomeAway":
            return reservation.homeAwayReservationID;

        case "Houfy":
            return reservation.houfyReservationID;

        default:
            return reservation?.airbnbConfirmationCode;
    }
}

function getFirstName(reservation: ReservationRaw): string {
    const {airbnbFirstName = "", custom} = reservation;
    // there's a case that `custom.firstName = ''`
    return custom?.firstName || airbnbFirstName;
}

function getLastName(reservation: ReservationRaw): string {
    const {airbnbLastName = "", custom} = reservation;
    return custom?.lastName || airbnbLastName;
}

function getEmail(reservation: ReservationRaw): string {
    const {airbnbEmail = "", custom} = reservation;
    return custom?.email || airbnbEmail;
}

function getPhone(reservation: ReservationRaw): string {
    const {airbnbPhone = "", custom} = reservation;
    return custom?.phone || airbnbPhone;
}

function getPreferredLocale(reservation: ReservationRaw): string {
    const {airbnbPreferredLocale = "", custom} = reservation;
    return custom?.preferredLocale || airbnbPreferredLocale || "default";
}

function formatNights(nights: number | undefined) {
    const nightsFormatted =
        typeof nights === "number" ? `${nights > 1 ? `${nights} nights` : `${nights} night`}` : "#";
    return nightsFormatted;
}

export function shortGuestName({firstName, lastName}: Reservation) {
    if (firstName && lastName) {
        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    }

    return "";
}

export function reservationStatusMap(status: ReservationRaw["status"]) {
    const mapping = {
        accepted: {
            label: "Accepted",
            color: "success.600",
            bgColor: "success.100",
            // this is on web
            classNames: "text-success"
        },
        inquiry: {
            label: "Inquiry",
            color: "purple.600",
            bgColor: "purple.100",
            classNames: ""
        },
        preapproved: {
            label: "Pre-Approved",
            color: "success.600",
            bgColor: "success.100",
            classNames: ""
        },
        denied: {
            label: "Denied",
            color: "red.500",
            bgColor: "red.100",
            classNames: "text-danger"
        },
        deny: {
            label: "Deny",
            color: "red.500",
            bgColor: "red.100",
            classNames: "text-danger"
        },
        timedout: {
            label: "Expired Pre-Approval",
            color: "red.500",
            bgColor: "red.100",
            classNames: ""
        },
        pending: {
            label: "Booking Request",
            color: "primary.500",
            bgColor: "primary.100",
            classNames: ""
        },
        cancelled: {
            label: "Cancelled",
            color: "red.500",
            bgColor: "red.100",
            classNames: "text-danger"
        },
        canceled: {
            label: "Cancelled",
            color: "red.500",
            bgColor: "red.100",
            classNames: "text-danger"
        },
        not_possible: {
            label: "Not possible",
            color: "blue.500",
            bgColor: "blue.100",
            classNames: "text-danger"
        },
        needs_update: {
            label: "Needs update",
            color: "red.500",
            bgColor: "red.100",
            classNames: ""
        }
    };
    const result = mapping[status];
    return (
        result ?? {
            label: status,
            color: "danger.500",
            classNames: ""
        }
    );
}

export function getTaxFeePrice(taxesFees: Required<ReservationRaw>["taxesFees"][number]) {
    let values = "";
    if (taxesFees.included) {
        values += "/Incl.";
    }
    if (taxesFees.perNight) {
        values += "/Ngt.";
    }
    if (taxesFees.perPerson) {
        values += "/Pers.";
    }
    return `${taxesFees.price}${values}`;
}
