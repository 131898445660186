import {useCallback, useEffect, useRef, useState} from "react";
import {Pagination} from "@hosttools/core/shared/model/pagination";

export interface RequestPayload {
    limit: number;
    offset: number;
}

interface Info<T> {
    hasMore: boolean;
    loading: boolean;
    offset: number;
    data: Array<T> | undefined;
    total: number;
    page: number;
    hasPrevPage: boolean;
    paginationNumbers: Array<number | string>;
}

interface UsePaginatedFetchOption {
    infinite: boolean;
}

const defaultPaginationInfo = {
    hasMore: true,
    loading: true,
    offset: 0,
    data: undefined,
    total: 0,
    page: 1,
    hasPrevPage: false,
    paginationNumbers: []
};

function usePaginatedFetch<T>(
    request: (params: RequestPayload) => Promise<Pagination<T> | undefined>,
    limit: number,
    deps: any[] = [],
    option: UsePaginatedFetchOption = {infinite: true}
) {
    // passing this would cause no rendering as `request` change
    // const requestRef = useRef(request);
    const currentUpdate = useRef<Promise<any>>();
    const [info, setInfo] = useState<Info<T>>(defaultPaginationInfo);

    const update = useCallback(
        async (offset: number, active = true) => {
            setInfo(prev => ({
                ...prev,
                loading: true,
                offset
            }));

            const paginationResult = await request({limit, offset});
            if (!paginationResult) {
                return;
            }
            const result = paginationResult.docs || [];

            // if (!mounted) {
            //   return;
            // }

            const getInfiniteData = (prevData: Info<T>["data"]) => {
                return offset === 0 ? result : ([] as T[]).concat(prevData || [], result);
            };

            if (!active) {
                return;
            }

            setInfo(prev => ({
                ...prev,
                loading: false,
                hasMore: result.length === limit,
                total: paginationResult.totalDocs,
                hasPrevPage: paginationResult.hasPrevPage,
                page: paginationResult.page,
                data: option.infinite ? getInfiniteData(prev.data) : result
            }));
        },
        [limit, option.infinite, request]
    );

    const refresh = useCallback(
        async (active = true) => {
            // Keep the data while refreshing looks like a better UX
            // const {data, ...defaultValues} = defaultPaginationInfo;
            setInfo(prev => ({
                ...prev,
                ...defaultPaginationInfo
            }));

            currentUpdate.current = update(0, active);

            return currentUpdate.current;
        },
        [update]
    );

    useEffect(() => {
        let active = true;
        refresh(active);
        return () => {
            active = false;
        };
    }, [...deps, refresh]);

    const fetchPage = async (page: number) => {
        if (currentUpdate.current) {
            await currentUpdate.current;
        }
        currentUpdate.current = update((page - 1) * limit);

        return currentUpdate.current;
    };

    const fetchMore = async () => {
        if (currentUpdate.current) {
            await currentUpdate.current;
        }
        if (info.hasMore) {
            currentUpdate.current = update(info.offset + limit);
        }

        return currentUpdate.current;
    };

    const fetchPrevious = async () => {
        if (currentUpdate.current) {
            await currentUpdate.current;
        }

        if (info.hasPrevPage) {
            currentUpdate.current = update(info.offset - limit);
        }

        return currentUpdate.current;
    };

    return {
        ...info,
        fetchMore,
        refresh,
        fetchPage,
        fetchPrevious
    };
}

export default usePaginatedFetch;
