import {UserContext} from "@hosttools/frontend";
import React, {memo, useMemo, useContext} from "react";
import gravatar from "gravatar";
import {HStack, Text, VStack} from "native-base";

import Avatar from "../Avatar";

const AvatarProfile: React.FC = () => {
    const {
        user: {fullName, username, subscriptionStatus}
    } = useContext(UserContext);

    const userImageUri = useMemo(
        () => ({uri: gravatar.url(username, {s: "120", r: "pg", d: "mp"})}),
        [username]
    );

    return (
        <HStack space={2} alignItems="center">
            <Avatar size="sm" source={userImageUri} name={fullName} />
            <VStack>
                <Text size="sm" textBreakStrategy="balanced">
                    {fullName}
                </Text>
                <Text size="xs" color="blueGray.500">
                    {subscriptionStatus}
                </Text>
            </VStack>
        </HStack>
    );
};

export default memo(AvatarProfile);
