import React, {
    memo,
    useCallback,
    forwardRef,
    type ForwardRefRenderFunction,
    useRef,
    useEffect
} from "react";
import {Input as InputNB, mergeRefs} from "native-base";
import {type InterfaceInputProps} from "native-base/lib/typescript/components/primitives/Input/types";

export type Props = Omit<InterfaceInputProps, "type"> & {
    name?: string;
    type?: InterfaceInputProps["type"] | "number";
};

function isNumeric(input: string) {
    return /^[0-9.-]+$/.test(input);
}

const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {type, name, onChangeText, ...props},
    ref
) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const handleChangeText = useCallback(
        (text: string) => {
            if (type !== "number") {
                onChangeText?.(text);
            }
            if (type === "number") {
                if (text === "" || isNumeric(text)) {
                    onChangeText?.(text);
                }
            }
        },
        [onChangeText, type]
    );

    useEffect(() => {
        if (innerRef.current && name) {
            innerRef.current.name = name;
        }
    }, [name]);

    return (
        <InputNB
            ref={mergeRefs([ref, innerRef])}
            type={type !== "number" ? type : undefined}
            size="md"
            variant="filled"
            bgColor="blueGray.100"
            onChangeText={handleChangeText}
            {...props}
        />
    );
};

export default memo(forwardRef(Input));
