export const fonts = {
    fontConfig: {
        Manrope: {
            500: {
                normal: "Manrope"
            },
            600: {
                normal: "Manrope"
            },
            700: {
                normal: "Manrope"
            }
        }
    },

    fonts: {
        heading: "Manrope",
        body: "Manrope",
        mono: "Manrope"
    }
};
