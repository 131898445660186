import classNames from "classnames";
import React, {useCallback, useState} from "react";
import {Modal} from "react-bootstrap";
import {FaCircleNotch} from "react-icons/fa";
import {memoWithType} from "@hosttools/frontend";

import ModalV2 from "@/client/components/Modal";
import useNewDesign from "@/client/hooks/useNewDesign";

interface BaseProps {
    show: boolean;
    title: string;
    message: React.ReactNode;
    size?: "md" | "xl";
    buttonText: string;
    btnColorScheme: "danger" | "primary";
    autoHide?: boolean;
    onHide: () => void;
}

export interface PropsWithData<T> extends BaseProps {
    data: T;
    onOk?: (data: T) => Promise<void> | void;
}

export interface PropsNoData<_T> extends BaseProps {
    onOk?: () => Promise<void> | void;
}

export type Props<T = unknown> = PropsWithData<T> | PropsNoData<T>;

const ModalConfirm = <T,>({
    show,
    title,
    message,
    size,
    btnColorScheme,
    buttonText,
    autoHide = true,
    onHide,
    ...props
}: Props<T>) => {
    const isNewUIEnabled = useNewDesign();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = useCallback(async () => {
        setIsLoading(true);
        if ("data" in props) {
            await props.onOk?.(props.data);
        } else {
            await props.onOk?.();
        }
        setIsLoading(false);
        if (autoHide) {
            onHide();
        }
    }, [autoHide, props, onHide]);

    const buttonClassnames = classNames("btn", {
        "btn-outline-primary": !btnColorScheme,
        "btn-outline-danger": btnColorScheme === "danger"
    });

    if (isNewUIEnabled) {
        return (
            <ModalV2
                size={size}
                title={title}
                isOpen={show}
                isLoading={isLoading}
                btnTextOk={buttonText}
                btnColorScheme={btnColorScheme}
                onSubmit={handleConfirm}
                onClose={onHide}
            >
                {message}
            </ModalV2>
        );
    }

    return (
        <Modal data-testid="modal-confirm" show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="pd-20 pd-sm-40">{message}</Modal.Body>

            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() => {
                        onHide();
                    }}
                >
                    Close
                </button>
                <button type="button" className={buttonClassnames} onClick={handleConfirm}>
                    {isLoading && <FaCircleNotch className="fa-spin mr-1" />}
                    {buttonText}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default memoWithType(ModalConfirm);
