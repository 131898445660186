import {Account as AccountModel} from "@hosttools/core/generated/models";

export type AccountRaw = MongooseModel2Client<AccountModel>;

export type Account = AccountRaw & {
    scope?: string;
};

export const sanitizeAccount = (account: Account): Account => {
    return {
        ...account,
        username: account.username ?? account.airbnbUsername
        // listings: [],
        // locks: []
    };
};
