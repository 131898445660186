const shadows = {
    "10": {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: -3
        },
        shadowOpacity: 0.05,
        shadowRadius: 14,
        elevation: 10
    },
    "11": {
        shadowColor: "#101828",
        shadowOffset: {
            width: 0,
            height: 4
        },
        shadowOpacity: 0.05,
        shadowRadius: 24,
        elevation: 10
    }
};

export default shadows;
