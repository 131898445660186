import {IIconProps, QuestionIcon} from "native-base";
import React, {memo} from "react";

import Popover from "../Popover";

export type Props = {
    label: React.ReactNode;
    iconProps?: IIconProps;
};

const PopoverInfo: React.FC<Props> = ({label, iconProps}) => {
    return (
        <Popover content={label} hasArrow>
            <QuestionIcon color="blueGray.400" size="3" {...iconProps} />
        </Popover>
    );
};

export default memo(PopoverInfo);
