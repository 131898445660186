import {Menu, Pressable, Link as UILink} from "native-base";
import React, {memo} from "react";
import {Link} from "react-router-dom";

type Internal = {
    link: string;
    type: "internal";
};

type External = {
    link: string;
    type: "external";
    isExternal?: boolean;
};

type FunctionLink = {
    link: () => void;
    type: "function";
};

export type MenuItemType = Internal | External | FunctionLink;

export type Props = MenuItemType & {
    children: React.ReactNode;
};

const MenuItemLink: React.FC<Props> = props => {
    return (
        <Menu.Item mx={-3} my={-1} px={0} borderRadius={4}>
            <>
                {props.type === "internal" && <Link to={props.link}>{props.children}</Link>}
                {props.type === "external" && (
                    <UILink href={props.link} isExternal={props.isExternal} width="full">
                        {props.children}
                    </UILink>
                )}
                {props.type === "function" && (
                    <Pressable onPress={props.link} width="full">
                        {props.children}
                    </Pressable>
                )}
            </>
        </Menu.Item>
    );
};

export default memo(MenuItemLink);
