import {AxiosInstance} from "axios";
import {createContext} from "react";

import {createHttp} from "../../http";

const http = createHttp("/");

const HTTPContext = createContext<AxiosInstance>(http);

export default HTTPContext;
