import React, {MouseEvent, useContext, useEffect, useRef} from "react";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {Toast} from "react-hot-toast/dist/core/types";
import {Reservation} from "@hosttools/core/shared/model/reservation";
import {Channel} from "@hosttools/core/shared/model/channel";
import {WebSocketContext} from "@hosttools/frontend";

import {BrowserNotification} from "../../utils/browserNotification";
import {UserContext} from "../../providers/UserProvider";
import useNotifySound from "../../hooks/useNotifySound";
import {useWatchNotification, Message} from "../../hooks/useWatchNotification";
import avatarUrl from "../../img/default-avatar.png";
import airbnbIconSolid from "../../img/airbnb-logo-solid-square.svg";
import bookingIconSolid from "../../img/booking-logo-solid-square.svg";
import homeawayIconSolid from "../../img/homeaway-logo-solid-square.svg";
import houfyIconSolid from "../../img/houfy-logo-solid-square.svg";

interface ToastOptions extends Message {
    onClick: () => void;
}

interface BrowserNotificationOptions {
    title: string;
    body: string;
    onClick: () => void;
}

const toastImgUrl: Record<"Avatar" | keyof typeof Channel, string> = {
    Avatar: avatarUrl,
    Airbnb: airbnbIconSolid,
    HomeAway: homeawayIconSolid,
    Booking: bookingIconSolid,
    Houfy: houfyIconSolid,
    internal: "",
    August: "",
    Seam: ""
};

const MessagesNotification: React.FC = () => {
    const ws = useContext(WebSocketContext);
    const {permissions} = useContext(UserContext);
    const browserNotifyRef = useRef<BrowserNotification | null>(null);
    const newNotification = useWatchNotification<Reservation>(ws);
    const history = useHistory();
    const browserNotification = browserNotifyRef.current;
    const toastIdRef = useRef<string>();
    const [, playSound] = useNotifySound();
    const hasPermissionViewInbox = permissions.inbox?.edit;
    useEffect(() => {
        browserNotifyRef.current = new BrowserNotification();
    }, []);

    useEffect(() => {
        if (!newNotification) {
            return;
        }

        const {type, document, message} = newNotification;
        const {_id} = document;
        const shouldOpenInboxPage = [
            "newInquiry",
            "newPending",
            "newReservation",
            "newCancelled",
            "newPost"
        ].includes(type);

        const onClick = () => {
            if (shouldOpenInboxPage) {
                history.push(`/inbox/${_id}`);
            } else {
                history.push(`/?reservationID=${_id}`);
            }
            if (toastIdRef.current) {
                toast.dismiss(toastIdRef.current);
            }
        };

        const toastOptions = {
            ...message,
            onClick
        };

        const browserNotificationOptions = {
            title: message.title,
            body: `${message.subTitle} \n${message.message}`,
            onClick
        };

        if (shouldOpenInboxPage && !hasPermissionViewInbox) {
            return;
        }

        generateToastNotify(toastOptions);
        generateBrowserNotification(browserNotificationOptions);
        playSound();
    }, [newNotification]);

    function generateToastNotify({title, subTitle, message, logoKey, onClick}: ToastOptions) {
        const handleToastClick = (t: Toast) => () => {
            toast.dismiss(t.id);
            onClick();
        };
        const handleCloseToast = (t: Toast) => (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            toast.dismiss(t.id);
        };
        toast(
            t => {
                toastIdRef.current = t.id;
                return (
                    <div
                        tabIndex={0}
                        className="toast-message"
                        role="button"
                        onClick={handleToastClick(t)}
                        onKeyPress={handleToastClick(t)}
                    >
                        {toastImgUrl[logoKey] && (
                            <img
                                src={toastImgUrl[logoKey]}
                                className="wd-40 mg-r-20"
                                alt={logoKey}
                            />
                        )}
                        <div className="content mg-r-20 pd-r-20">
                            <h6 className="title text-truncate">{title}</h6>
                            <div className="sub-title text-muted tx-11 text-truncate">
                                {subTitle}
                            </div>
                            <div className="message text ellipsis2">{message}</div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleCloseToast(t)}
                        >
                            Close
                        </button>
                    </div>
                );
            },
            {
                duration: 10000, // 10 seconds,
                position: "top-right",
                style: {
                    width: 400,
                    maxWidth: 400
                }
            }
        );
    }

    function generateBrowserNotification({title, body, onClick}: BrowserNotificationOptions) {
        if (browserNotification) {
            browserNotification.showNotification(
                title,
                {
                    body
                },
                onClick
            );
        }
    }

    return null;
};

export default MessagesNotification;
