import React, {memo, useCallback} from "react";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";
import {FormControl} from "native-base";

import Label from "../Label";
import Input from "../Input";

import type {CredentialsType, Errors} from "@/admin/components/ModalAddAccount/types";

export type Props = {
    credentials: CredentialsType;
    errors?: Errors;
    onChangeCredentials: (field: "username" | "password", value: string) => void;
};

const AugustCredential: React.FC<Props> = ({credentials, errors, onChangeCredentials}) => {
    const {label} = useChannel("August");

    const handleChangeUsername = useCallback(
        (text: string) => {
            onChangeCredentials("username", text);
        },
        [onChangeCredentials]
    );

    const handleChangePassword = useCallback(
        (text: string) => {
            onChangeCredentials("password", text);
        },
        [onChangeCredentials]
    );

    return (
        <>
            <FormControl isInvalid={!!errors?.username}>
                <Label
                    tooltip={`Enter the email address you use to login to your ${label} account.`}
                >
                    {label} Email
                </Label>
                <Input
                    id="username"
                    placeholder={`${label} Email...`}
                    name="username"
                    type="text"
                    autoComplete="off"
                    value={credentials.username}
                    onChangeText={handleChangeUsername}
                    isRequired
                />
                <FormControl.ErrorMessage>{errors?.username}</FormControl.ErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors?.password}>
                <Label tooltip={`Enter your ${label} password.`}>{label} Password</Label>
                <Input
                    id="password"
                    placeholder={`${label} Password...`}
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={credentials.password}
                    onChangeText={handleChangePassword}
                    isRequired
                />
                <FormControl.ErrorMessage>{errors?.password}</FormControl.ErrorMessage>
            </FormControl>
        </>
    );
};

export default memo(AugustCredential);
