import {Listing} from "@hosttools/frontend/models/listing";
import {Button, Center, SearchIcon, Text, VStack} from "native-base";
import React, {memo, useState, useMemo, useCallback} from "react";
import {FiX} from "react-icons/fi";

import Input from "../Input";
import ListingSlat from "../ListingSlat";
import ItemLink from "../Header/MenuItemLink";

import useFilteredListings from "./useFilteredListings";

type Props = {
    listings: Listing[];
    to: "messaging" | "pricing";
};

const ListingsMenu: React.FC<Props> = ({listings, to}) => {
    const [query, setQuery] = useState("");

    const filteredListings = useFilteredListings(listings, query);

    const handleClearSearch = useCallback(() => setQuery(""), []);

    const InputRightElement = useMemo(
        () =>
            query ? (
                <Button variant="unstyled" onPress={handleClearSearch}>
                    <FiX />
                </Button>
            ) : undefined,
        [handleClearSearch, query]
    );

    return (
        <VStack space={3}>
            <Text fontWeight="bold">Listings</Text>
            <Input
                placeholder="Search..."
                value={query}
                onChangeText={setQuery}
                InputLeftElement={<SearchIcon color="blueGray.400" size="sm" ml={3} />}
                InputRightElement={InputRightElement}
            />
            <VStack space={2}>
                {filteredListings.map(listing => (
                    <ItemLink key={listing._id} link={`/${to}/${listing._id}`} type="internal">
                        <ListingSlat listing={listing} size="xs" includeChannel={false} />
                    </ItemLink>
                ))}
                {filteredListings.length === 0 && (
                    <Center>
                        <Text>No listing found</Text>
                    </Center>
                )}
            </VStack>
        </VStack>
    );
};

export default memo(ListingsMenu);
