import React, {memo} from "react";
import {Box, IBoxProps} from "native-base";

const styles = {
    container: {
        display: ["flex", "flex", "none"]
    }
};

export type Props = IBoxProps;

const MobileView: React.FC<Props> = ({children, ...restProps}) => {
    return (
        <Box {...styles.container} {...restProps}>
            {children}
        </Box>
    );
};

export default memo(MobileView);
