import React, {memo, useCallback, useContext, useEffect, useState} from "react";
import {FaCircleNotch} from "react-icons/fa";
import {FiRefreshCcw, FiSettings} from "react-icons/fi";
import {Link} from "react-router-dom";

import {UserContext} from "../../providers/UserProvider";
import Banner from "../Banner";
import ModalAddAccount from "../ModalAddAccount";
import ModalDownloading from "../ModalAddAccount/ModalDownloading";
import ModalIntroduction from "../ModalAddAccount/ModalIntroduction";

import ModalMessage from "./ModalMessage";

import {ErrorContext} from "@/admin/providers/ErrorProvider";

// Errors:
//  user
//      user_not_active
//  account
//      account_added_after_trial
//      no_accounts
//      no_active_accounts
//      non_active_accounts
//
//  listing
//      no_listings
//      no_active_listings
//      listings_updating
//      listing_unlisted
//      listing_prices_not_downloaded
//
//  reservation
//      no_reservations
//
//  timeline
//      no_time_based_message_rules
//
//  messageRule
//      no_message_rules
//      no_listing_message_rules
//
//  pricingRules
//      no_listing_minimum_price
//      listing_pricing_paused
//      listing_pricing_enabled_no_enabled_rules
//      no_listing_pricing_rules
//      no_listing_enabled_pricing_rules
//
//  billing
//      credit_card ?

type ShowSpinner = {
    noListings?: boolean;
};

const Errors: React.FC = () => {
    const [showAddAccountModal, setShowAddAccountModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState<ShowSpinner>({});
    const [showDownloading, setShowDownloading] = useState(false);
    const [showIntroduction, setShowIntroduction] = useState(false);
    const {errors, updateUser, user} = useContext(UserContext);
    const {onRefresh} = useContext(ErrorContext);

    const isSubUser = !!user.originUserID;

    useEffect(() => {
        if (errors.noAccounts && user.subscriptionStatus !== "active") {
            setShowAddAccountModal(true);
        }
    }, [errors.noAccounts, user.subscriptionStatus]);

    const handleCloseModal = useCallback(
        async isSuccess => {
            if (isSuccess) {
                setShowIntroduction(true);
                await updateUser();
            }
            setShowAddAccountModal(false);
        },
        [updateUser]
    );

    const handleFinishIntroduction = useCallback(() => {
        setShowIntroduction(false);
    }, []);

    const handleHideIntroduction = useCallback(() => {
        setShowDownloading(true);
        setShowIntroduction(false);
    }, []);

    const handleHideDownloadingModal = useCallback(() => {
        setShowDownloading(false);
    }, []);

    if (!errors) {
        return null;
    }

    const settingButton = (
        <Link className="btn btn-outline-danger" to="/settings">
            <FiSettings className="mr-2" />
            Settings
        </Link>
    );

    const refreshButton = (
        <button
            type="button"
            className="btn btn-outline-dark"
            onClick={async () => {
                setShowSpinner(showSpinner => {
                    return {...showSpinner, noListings: true};
                });
                await onRefresh?.();
                setShowSpinner(showSpinner => {
                    return {
                        ...showSpinner,
                        noListings: false
                    };
                });
            }}
        >
            {!showSpinner.noListings && <FiRefreshCcw className="mr-1" />}
            {showSpinner.noListings && (
                <FaCircleNotch data-testid="spinner" className="fa-spin mr-1" />
            )}
            Refresh
        </button>
    );

    let bannerRenderer: React.ReactElement | null = null;

    if (errors.userNotActive) {
        bannerRenderer = (
            <Banner alertClass="alert-danger">
                <p className="card-text">
                    <strong>Subscribe Today! </strong>
                    Your account is no longer active which means that all Host Tools automation has
                    stopped for your listings. Please visit the&nbsp;
                    <Link to="/billing">billing page</Link>
                    &nbsp;and add your credit card to subscribe and re-activate your account.
                </p>
            </Banner>
        );
    }

    // if (errors.userNotActive && errors.accountAddedAfterTrial) {
    //     bannerRenderer = (
    //         <Banner alertClass="alert-danger">
    //             <p className="card-text">
    //                 <strong>Your data will download as soon as you have subscribed.&nbsp;</strong>
    //                 You added an account after your trial has finished. Please subscribe and Host
    //                 Tools will immediately download your your data.
    //             </p>
    //         </Banner>
    //     );
    // }

    if (!errors.userNotActive && (errors.noActiveAccounts || errors.nonActiveAccounts)) {
        bannerRenderer = (
            <Banner alertClass="alert-danger" actionRenderer={settingButton}>
                <p className="card-text">
                    <strong>Your account is disconnected.&nbsp;</strong>
                    Your account needs to be connected to Host Tools. Please browse the&nbsp;
                    <Link to="/settings">settings page</Link>
                    &nbsp;and connect your account.
                </p>
            </Banner>
        );
    }

    if (
        !errors.userNotActive &&
        !errors.noAccounts &&
        !errors.noActiveAccounts &&
        errors.noListings
    ) {
        bannerRenderer = (
            <Banner alertClass="alert-warning" actionRenderer={refreshButton}>
                <p className="card-text">
                    <strong>No listings found! </strong>
                    Host Tools didn&apos;t find any listings associated with your account. If
                    you&apos;re account was recently added it can take a few minutes for Host Tools
                    to download your listings. Just wait a few minutes and then hit the refresh
                    button.
                    <br />
                    If you recently added a new listing, go to the&nbsp;
                    <Link to="/settings">settings page</Link>
                    &nbsp;on Host Tools and click the &quot;Refresh&quot; button next to your
                    account to download your latest listings.
                </p>
            </Banner>
        );
    }

    if (
        !errors.userNotActive &&
        !errors.noActiveAccounts &&
        // !errors.listingsUpdating &&
        errors.noActiveListings
    ) {
        bannerRenderer = (
            <Banner alertClass="alert-warning">
                <p className="card-text">
                    <strong>All your listings are disabled! </strong>
                    All your listings have been disabled. This means you&apos;ve effectively paused
                    Host Tools. Please visit the&nbsp;
                    <Link to="/settings">settings page</Link>
                    &nbsp;and re-enable your listings.
                </p>
            </Banner>
        );
    }

    if (
        !errors.userNotActive &&
        !errors.noActiveAccounts &&
        errors.noVisibleListings &&
        isSubUser
    ) {
        bannerRenderer = (
            <Banner alertClass="alert-warning">
                <p className="card-text">
                    This account is associated with a Listing Group that has child listings linked
                    to it. Please ask the owner to remove the listings and then add them again to
                    this listing group. Doing this will remove and child listings and only link
                    parent listings.
                </p>
            </Banner>
        );
    }

    // if (!errors.userNotActive && errors.listingUnlisted) {
    //     bannerRenderer = (
    //         <Banner alertClass="alert-warning">
    //             <p className="card-text">
    //                 <strong>This listing is not listed. </strong>
    //                 This feature is only available to listing that are currently active and listed.
    //                 Please re-list your listing, visit the&nbsp;
    //                 <Link to="/settings">settings page</Link>
    //                 &nbsp;and click the &quot;Refresh&quot; button next to your account to download
    //                 the latest listing data.
    //             </p>
    //         </Banner>
    //     );
    // }

    // if (
    //     !errors.userNotActive &&
    //     !errors.noAccounts &&
    //     !errors.noListings &&
    //     !errors.noTimeBasedMessageRules &&
    //     errors.noReservations
    // ) {
    //     bannerRenderer = (
    //         <Banner>
    //             <p className="card-text">
    //                 <strong>No Reservations. &nbsp;</strong>
    //                 Host Tools is still downloading your reservations, this can sometimes take a few
    //                 minutes. If you recently connected your account your reservations will aprear in
    //                 a few minutes.
    //             </p>
    //         </Banner>
    //     );
    // }

    if (
        !errors.userNotActive &&
        !errors.noAccounts &&
        !errors.noListings &&
        // !errors.noTimeBasedMessageRules &&
        errors.fetchingListingNewAccount
    ) {
        bannerRenderer = (
            <Banner>
                <p className="card-text">
                    <strong>Downloading listings. &nbsp;</strong>
                    Host Tools is still downloading your listings, this can sometimes take a few
                    minutes. If you recently connected your account your listings will aprear in a
                    few minutes.
                </p>
            </Banner>
        );
    }

    // if (
    //     !errors.user_not_active &&
    //     errors.listing_pricing_enabled_no_enabled_rules &&
    //     !errors.listing_unlisted
    // ) {
    //     bannerRenderer = (
    //         <Banner alertClass="alert-warning" actionRenderer={addPriceRuleButton}>
    //             <p className="card-text">
    //                 <strong>Pricing tool is enabled but there are no pricing rules.&nbsp;</strong>
    //                 The pricing tool is currently enabled but there are no pricing rules. That means
    //                 that every time Host Tools syncs your prices it will overwrite the current with
    //                 the Prices shown on Host Tools. If you don&apos;t want that, please add a
    //                 pricing rule or pause the pricing tool.
    //                 <br />
    //                 <Link to="/FAQ#pricingRules">More about pricing rules</Link>
    //             </p>
    //         </Banner>
    //     );
    // }

    //
    //
    //
    // Temp banner for beta users we need to talk to
    //
    //
    const isBetaUserWeNeedToTalkTo = [
        // "604f9301b7ef9d23ff3c7798", // testtest
        // "5b11dcdd44b20200087bd13d", // neil.aneja@gmail.com
        // "5b8745cecdda3300087fcfaa", // vannaguo@gmail.com
        // "5ae2377189fab400084846e7", // str@cgstl.com
        "5b61ad9eeb39810008e85854" // stefanpauloswald@gmail.com
        // "5b6b787699cb7a0008a4c8b6", // jose.g.yapor@gmail.com
        // "5b85c0a8c8056c000823c5a3" // gerardi.florian@gmail.com
    ].includes(user._id);
    const isBetaUserThatHaveNotEmailed = [
        // "604f9301b7ef9d23ff3c7798", // testtest
        // "5b11dcdd44b20200087bd13d", // neil.aneja@gmail.com
        // "5b8745cecdda3300087fcfaa", // vannaguo@gmail.com
        // "5ae2377189fab400084846e7", // str@cgstl.com
        "5b61ad9eeb39810008e85854" // stefanpauloswald@gmail.com
        // "5b6b787699cb7a0008a4c8b6" // jose.g.yapor@gmail.com
        // "5b85c0a8c8056c000823c5a3" // gerardi.florian@gmail.com
    ].includes(user._id);
    if (isBetaUserWeNeedToTalkTo) {
        bannerRenderer = (
            <Banner alertClass="alert-danger">
                <p className="card-text">
                    <h1>We need your help! </h1>
                    <br />
                    Host Tools has been accepted as a Preferred Partner with VRBO/Expedia group and
                    we need your help.
                    <br />
                    <br />
                    {"Tom sent you an email about joining the VRBO Pilot Program. "}
                    <strong>We need your response right away</strong>
                    <strong>
                        , please send Tom an email to
                        <a
                            target="_blank"
                            href={`mailto:t@hosttools.com?subject=VRBO Pilot Program&body=%0d%0a%0d%0a%0d%0a%0d%0a--------%0d%0aBeta UserID: ${user._id}`}
                            rel="noreferrer"
                        >
                            {" t@hosttools.com "}
                        </a>
                        now.
                    </strong>
                    <br />
                    <br />
                    We need a response from all Beta users right away.
                    <br />
                    <br />
                    <strong>
                        You are seeing this message because Tom is waiting for your response about
                        the VRBO Pilot Program and we need to hear from you.
                    </strong>
                </p>
            </Banner>
        );
    }
    //
    //
    //
    // bannerRenderer = (
    //     <Banner alertClass="alert-danger">
    //         <p className="card-text">
    //             We&apos;re experiencing connection issues with VRBO. Calendar syncing, messaging,
    //             and listing updates are not working at this time. We are working on a
    //             solution.&nbsp;
    //             <a target="_blank" href="https://hosttools.instatus.com/" rel="noreferrer">
    //                 More details here.
    //             </a>
    //         </p>
    //     </Banner>
    // );

    return (
        <>
            {bannerRenderer}
            {/* {!errors.userNotActive &&
            !errors.nonActiveAccounts &&
            errors.listingsUpdating && (
                <ToastComponent title="Downloading Data...">
                    Host Tools is in the process of downloading your account&apos;s data. This
                    typically takes just a few seconds but can take a few minutes for larger
                    accounts. This page will automatically refresh when the new data is available.
                </ToastComponent>
            )} */}
            {isBetaUserWeNeedToTalkTo && (
                <ModalMessage user={user} hideCloseButtons={isBetaUserThatHaveNotEmailed} />
            )}
            {showAddAccountModal && (
                <ModalAddAccount
                    show={showAddAccountModal}
                    onHide={handleCloseModal}
                    hideCloseButtons={errors.noAccounts}
                />
            )}
            {showIntroduction && (
                <ModalIntroduction
                    onFinish={handleFinishIntroduction}
                    onHide={handleHideIntroduction}
                />
            )}
            {showDownloading && <ModalDownloading onHide={handleHideDownloadingModal} />}
        </>
    );
    /* {!errors.user_not_active && errors.no_accounts && (
                    <div className="card mg-t-20 mg-b-20">
                        <div className="card-body">
                            <h5>Get started</h5>
                            <p className="card-text">
                                Welcome to Host Tools, to get started let&apos;s link your
                                account. Click the &quot;Add Account&quot; button below
                                and follow the prompts.
                            </p>
                        </div>
                        <div className="card-footer bd-t tx-right">
                            <button
                                type="button"
                                className="btn btn-outline-dark"
                                onClick={this.handleShowAddAccount}
                            >
                                <FiPlus className="mr-1" />
                                Add Account
                            </button>
                        </div>
                    </div>
                )} */

    /* {!errors.user_not_active &&
                    !errors.no_active_accounts &&
                    !errors.no_accounts &&
                    errors.no_message_rules &&
                    !errors.listings_updating && (
                        <div className="card mg-t-20 mg-b-20">
                            <div className="card-body">
                                <h5>Just one more step</h5>
                                <p className="card-text">
                                    You&apos;ve added your account, now add a message rule.
                                    Automated messages, emails, SMS or reviews are sent when a
                                    message rule&apos;s parameters are met.
                                </p>
                                <p className="card-text">
                                    To create a message rule, click on Messaging at the menu at the
                                    top of the page.
                                </p>
                                <Link to="/FAQ#messageRules">More about message rules</Link>
                            </div>
                        </div>
                    )} */

    /* {!errors.user_not_active &&
                    !errors.no_accounts &&
                    !errors.no_message_rules &&
                    errors.no_time_based_message_rules && (
                        <div className="card mg-t-20 mg-b-20">
                            <div className="card-body">
                                <h5>Add a scheduled message rule</h5>
                                <p className="card-text">
                                    Only scheduled message rules will show up in the timeline and
                                    you haven&apos;t created any scheduled message rules yet.
                                    Scheduled message rules are messages rules that are sent based
                                    on the check-in or check-out of a reservation. Message rules
                                    that send on an event like a new booking or inquiry don&apos;t
                                    show up in the timeline until they are sent.
                                </p>
                                <p className="card-text">
                                    To create a message rule, click on Messaging at the menu at the
                                    top of the page.
                                </p>
                                <Link to="/FAQ#messageRules">More about message rules</Link>
                            </div>
                        </div>
                    )} */

    /* {!errors.user_not_active &&
                    !errors.listing_pricing_enabled_no_enabled_rules &&
                    errors.no_listing_pricing_rules &&
                    !errors.listing_unlisted && (
                        <div className="card mg-t-20 mg-b-20">
                            <div className="card-body">
                                <h5 className="card-title tx-dark tx-medium mg-b-10">
                                    Add a pricing rule
                                </h5>
                                <p className="card-text">
                                    Start build a pricing strategy by creating a series of pricing
                                    rules that define your listing&apos;s prices. Click the
                                    &quot;Add Rule&quot; button to create the first pricing rule.
                                </p>
                                <Link to="/FAQ#pricingRules">More about pricing rules</Link>
                            </div>
                            <div className="card-footer bd-t tx-right">
                                <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={showAddPricingRule}
                                >
                                    <FiPlus className="mr-1" />
                                    Add Rule
                                </button>
                            </div>
                        </div>
                    )} */
};

export default memo(Errors);
