import {Text, Pressable, ChevronRightIcon, HStack} from "native-base";
import React, {memo} from "react";

type Props = {
    children: string;
    onPress?: () => void;
    hasIcon?: boolean;
};

const MenuLinkMobile: React.FC<Props> = ({children, hasIcon, onPress}) => {
    return (
        <Pressable
            width="full"
            py={2}
            px={2}
            borderRadius={4}
            _pressed={{bgColor: "blue.50"}}
            onPress={onPress}
        >
            <HStack alignItems="center" justifyContent="space-between">
                <Text fontWeight="semibold">{children}</Text>
                {hasIcon && <ChevronRightIcon color="blueGray.400" />}
            </HStack>
        </Pressable>
    );
};

export default memo(MenuLinkMobile);
