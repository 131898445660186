import {useMemo} from "react";

import {Listing, ListingChannel} from "../../../models/listing";

const useChannelsFromListings = (listings: Listing[]) => {
    return useMemo(() => {
        return listings.reduce((result, listing) => {
            if (!listing.pricingEnabled) {
                return result;
            }
            if (!result.includes(listing.source)) {
                result.push(listing.source);
            }
            // also add any linked listing's channel
            result = (listing.linkedListings || []).reduce((arr, linkedListing) => {
                if (!arr.includes(linkedListing.source)) {
                    arr.push(linkedListing.source);
                }
                return arr;
            }, result);

            return result;
        }, [] as ListingChannel[]);
    }, [listings]);
};

export default useChannelsFromListings;
