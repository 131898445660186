import {Pressable, Text} from "native-base";
import React, {memo} from "react";
import {Link} from "react-router-dom";

type Props = {
    to: string;
    children: string;
    isActive?: boolean;
};

const MenuLink: React.FC<Props> = ({to, children, isActive}) => {
    return (
        <Pressable
            height="100%"
            display="flex"
            justifyContent="center"
            borderBottomColor={isActive ? "blue.600" : "transparent"}
            color={isActive ? "blue.600" : ""}
            borderStyle="solid"
            borderBottomWidth="2px"
            _hover={{color: "blue.600", borderBottomColor: "blue.600"}}
            mx={[2, 4]}
        >
            <Link to={to}>
                <Text color={isActive ? "blue.600" : undefined}>{children}</Text>
            </Link>
        </Pressable>
    );
};

export default memo(MenuLink);
