import {useBreakpointValue} from "native-base";

function useMobileView<T = any>(
    mobileValue?: T,
    desktopValue?: T
): IsAny<T> extends true ? boolean : T {
    const value = useBreakpointValue({
        base: typeof mobileValue === "undefined" ? true : mobileValue,
        md: typeof desktopValue === "undefined" ? false : desktopValue
    });
    return value;
}

export default useMobileView;
