import {useEffect, useMemo, useState} from "react";
import {useUserContext} from "@hosttools/frontend";

import type {QuickGuide} from "./type";

import {useWatchMessageRule} from "@/admin/hooks/useWatchMessageRule";

const TOTAL_STEPS = 6;

function useQuickGuide(): {
    quickGuide: QuickGuide | undefined;
    numberCompleted: number | undefined;
    totalSteps: number;
    isAllStepCompleted: boolean;
} {
    const {listings, user} = useUserContext();
    const [messageRules] = useWatchMessageRule(undefined, true);

    const [quickGuide, setQuickGuide] = useState<QuickGuide>();

    const numberCompleted = useMemo(
        () =>
            quickGuide
                ? Object.keys(quickGuide).filter(key => quickGuide?.[key]).length + 1
                : undefined,
        [quickGuide]
    );

    useEffect(() => {
        if (listings && user?.accounts && messageRules) {
            setQuickGuide(prev => ({
                ...prev,
                ...listings.reduce<QuickGuide>((result, listing) => {
                    if (listing.linkedListingID) {
                        result.linkListing = true;
                    }
                    if (listing.basePrice || listing.minPrice || listing.priceSource) {
                        result.configPricing = true;
                    }
                    if (listing.listingEnabled) {
                        result.enableListing = true;
                    }
                    return result;
                }, {}),
                connectAccount: !!user.accounts.find(
                    account => !!account.lastLoginAttemptSuccessful
                ),
                enableMessageRule: !!messageRules.find(messageRule => !messageRule.paused)
            }));
        }
    }, [listings, messageRules, user.accounts]);

    return useMemo(
        () => ({
            quickGuide,
            numberCompleted,
            isAllStepCompleted: numberCompleted === TOTAL_STEPS,
            totalSteps: TOTAL_STEPS
        }),
        [numberCompleted, quickGuide]
    );
}

export default useQuickGuide;
