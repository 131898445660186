import {Channel} from "@hosttools/core/shared/model/channel";
import axios from "axios";

import {MessageRule} from "../models/messageRule";
import {Listing} from "../models/listing";
import {User} from "../models/user";
import {Account} from "../models/account";
import {PricingRule} from "../models/pricingRule";

export interface ErrorsType {
    hasErrors?: boolean;
    userNotActive?: boolean;
    noAccounts?: boolean;
    noActiveAccounts?: boolean;
    nonActiveAccounts?: boolean;
    noListings?: boolean;
    noActiveListings?: boolean;
    noVisibleListings?: boolean;
    fetchingListingNewAccount?: boolean;
}

export type ErrorsListingType = {
    noListingPricingRules?: boolean;
    noListingEnabledPricingRules?: boolean;
    noListingMinimumPrice?: boolean;
    noListingBasePrice?: boolean;
    listingPricingPaused?: boolean;
    listingUnlisted?: boolean;
    listingPricesNotDownloaded?: boolean;
    listingDisabled?: boolean;
};

export interface ErrorsPricingType extends ErrorsListingType {
    noListingPricingRules?: boolean;
    noListingEnabledPricingRules?: boolean;
}

export interface ErrorsMessagingType extends ErrorsListingType {
    noListingMessageRules?: boolean;
}

export const buildErrorsPricing = (
    pricingRules: PricingRule[],
    listing?: Listing,
    pricingRule?: PricingRule
) => {
    const errors: ErrorsPricingType = buildErrorsListing(listing);
    const pricingRuleCount = pricingRules.length;

    if (pricingRule) {
        const pricingRulesEnabledCount = pricingRules.filter(pricingRule => {
            return pricingRule.paused === false;
        }).length;
        errors.noListingPricingRules = pricingRuleCount === 0;
        errors.noListingEnabledPricingRules = pricingRulesEnabledCount === 0;
    }

    return errors;
};

export const buildErrorsMessaging = (messageRules: MessageRule[], listing?: Listing) => {
    const errors: ErrorsMessagingType = buildErrorsListing(listing);

    errors.noListingMessageRules = messageRules.length === 0;

    return errors;
};

function buildErrorsListing(listing?: Listing) {
    const errors: ErrorsListingType = {};

    if (listing) {
        errors.noListingMinimumPrice =
            !listing.minPrice && listing.priceSource !== "Beyond Pricing";
        errors.noListingBasePrice = !listing.basePrice && listing.priceSource === "Amount";
        errors.listingPricingPaused = !listing.pricingEnabled;
        errors.listingUnlisted = listing.airbnbStatus ? listing.airbnbStatus !== "listed" : true; // not sure the default value is right?
        errors.listingPricesNotDownloaded =
            !listing.pricesDownloadedLast && !listing.pricesUpdatedLast;
        errors.listingDisabled = !listing.listingEnabled;
    }

    return errors;
}

export function buildGlobalErrors(
    user: User,
    accounts: Account[],
    listings: Listing[],
    visibleListings: Listing[]
) {
    const errors: ErrorsType = {};
    // User
    if (user.subscriptionStatus !== "active" && user.subscriptionStatus !== "trialing") {
        errors.userNotActive = true;
        errors.hasErrors = true;
    }

    const badAccounts = accounts.filter(account => {
        return account.lastLoginAttemptSuccessful === false;
    });
    const linkedAccounts = accounts.filter(account => {
        return account.lastLoginAttemptSuccessful !== false;
    });

    if (!user.originUserID) {
        if (linkedAccounts.length === 0 && badAccounts.length === 0) {
            errors.noAccounts = true;
            errors.hasErrors = true;
        } else {
            // Only show these errors if accounts already exist
            if (linkedAccounts.length === 0) {
                errors.noActiveAccounts = true;
                errors.hasErrors = true; // Don't want to show Timeline if there are no active accounts
            }
            if (badAccounts.length !== 0) {
                errors.nonActiveAccounts = true;
                // errors.hasErrors = true; // Still want to show the timeline if there are bad accounts and no good accounts
            }
        }
    }

    const accountFetchingListings = accounts.find(
        account =>
            !account.listingsUpdatedLast &&
            Channel.August !== account.type &&
            Channel.Seam !== account.type
    );

    if (accountFetchingListings) {
        errors.fetchingListingNewAccount = true;
        return errors;
    }

    // listing error
    if (listings.length === 0) {
        errors.noListings = true;
        errors.hasErrors = true;
    }
    const foundEnabledListings = listings.some(listing => {
        return listing.listingEnabled;
    });
    if (listings.length !== 0 && (!foundEnabledListings || visibleListings.length === 0)) {
        errors.noActiveListings = true;
        errors.hasErrors = true;
    }

    if (listings.length !== 0 && foundEnabledListings && visibleListings.length === 0) {
        errors.noVisibleListings = true;
        errors.hasErrors = true;
    }

    return errors;
}

export function getErrorMessageAxios(errorRes: Error) {
    if (axios.isAxiosError(errorRes)) {
        const {response} = errorRes;
        if (response) {
            const {data} = response;
            return data.message;
        }
    }
}
