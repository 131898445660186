import {init} from "@amplitude/analytics-browser";
import gravatar from "gravatar";
import LogRocket from "logrocket";
import {
    Avatar,
    Badge as BadgeNB,
    Box,
    Button,
    HStack,
    HamburgerIcon,
    IMenuProps,
    IconButton,
    Image,
    Input,
    Menu,
    Pressable,
    QuestionIcon,
    SearchIcon,
    Text,
    Link as UILink
} from "native-base";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Badge, Nav, NavDropdown, Navbar} from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import {FullStoryAPI} from "react-fullstory";
import TagManager from "react-gtm-module";
import {hotjar} from "react-hotjar";
import {
    FiCalendar,
    FiChevronLeft,
    FiCreditCard,
    FiExternalLink,
    FiHelpCircle,
    FiMessageCircle,
    FiMessageSquare,
    FiPlus,
    FiSearch,
    FiSettings,
    FiUser,
    FiX
} from "react-icons/fi";
import {Link, Redirect, matchPath, useHistory, useLocation} from "react-router-dom";

import {
    INBOX_PATH,
    MESSAGING_PATH,
    MULTICALENDAR_PATH,
    PRICING_PATH,
    TOP_MENU_HEIGHT_DESKTOP,
    TOP_NEW_UI_BANNER_HEIGHT
} from "../constant";
import logoBlack from "../img/logo-black.svg";
import logoSquare from "../img/logo-icon-square.svg";
import {UserContext} from "../providers/UserProvider";
import {BrowserNotification} from "../utils/browserNotification";

import Banner from "./Banner";
import "./Header.css";
import ModalAddAccount from "./ModalAddAccount";
import NavMessaging from "./NavMessaging";
import NavPricing from "./NavPricing";

import useNewDesign from "@/client/hooks/useNewDesign";
import QuickGuideButton from "@/client/features/QuickGuide/QuickGuideButton";
import MenuLink from "@/client/components/Header/MenuItemDesktop";
import DesktopView from "@/client/components/DesktopView";
import MobileView from "@/client/components/MobileView";
import ModalMenuMobile from "@/client/components/Header/ModalMenuMobile";
import MenuProfile from "@/client/components/Header/MenuProfile";
import {AppContext} from "@/client/provider/AppProvider";

const logoSource = {uri: logoBlack};

const Header: React.FC = () => {
    const {
        user,
        user: {firstName, lastName, username, subscriptionStatus},
        listings,
        visibleListings,
        listingGroups,
        permissions,
        isAuthenticated
    } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();
    const isNewUIEnabled = useNewDesign();
    const {setNewUIContext} = useContext(AppContext);
    const {search, pathname} = location;

    const [showAddAccountModal, setShowAddAccountModal] = useState(false);
    const [showModalMenuMobile, setShowModalMenuMobile] = useState(false);
    const [showProfileNav, setShowProfileNav] = useState(false);
    const [query, setQuery] = useState(new URLSearchParams(search).get("q") || "");
    const [showBannerAskNotification, setShowBannerAskNotification] = React.useState(false);
    const browserNotifyRef = useRef<BrowserNotification | null>(null);
    const browserNotification = browserNotifyRef.current;
    const hasPermissionEditInbox = permissions.inbox?.edit;
    const hasPermissionEditPricing = permissions.pricing?.edit;
    const hasPermissionEditAccounts = permissions.accounts?.edit;
    const hasPermissionEditBillings = permissions.billing?.edit;
    const hasPermissionEditMessageRules = permissions.messageRules?.edit;
    const hasPermissionEditUsers = permissions.userManagement?.edit;

    const userImage = useMemo(
        () => gravatar.url(username, {s: "120", r: "pg", d: "mp"}),
        [username]
    );

    const triggerUserProfile: IMenuProps["trigger"] = useCallback(
        props => {
            return (
                <Pressable accessibilityLabel="User profile" {...props}>
                    <Avatar size="sm" source={{uri: userImage}} />
                </Pressable>
            );
        },
        [userImage]
    );

    const inputLeftElement = useMemo(
        () => (
            <Box pl={3}>
                <SearchIcon color="blueGray.400" />
            </Box>
        ),
        []
    );

    const inputRightElement = useMemo(
        () =>
            query ? (
                <Button variant="unstyled" onPress={handleClearQuery}>
                    <FiX />
                </Button>
            ) : undefined,
        [query]
    );

    useEffect(() => {
        browserNotifyRef.current = new BrowserNotification();
    }, []);

    useEffect(() => {
        init(
            __IS_BETA__ ? "1d6a794b4b3f13c77d14c5d3b5e7c994" : "5bee0782367d6c51f29fed1d8869f86b",
            user._id
        );
        const tagManagerArgs = {
            gtmId: "GTM-59XQPWR",
            dataLayer: {
                userId: user._id
            }
        };
        TagManager.initialize(tagManagerArgs);
        let matching = {} as any;
        if (isAuthenticated) {
            matching = {external_id: user._id};
        }
        ReactPixel.init("2039536889686642", matching);
        ReactPixel.pageView(); // For tracking page view

        FullStoryAPI("identify", user._id, {
            displayName: user.firstName ? `${user.firstName} ${user.lastName}` : "",
            email: user.username
        });

        if (
            process.env.NODE_ENV === "production" &&
            user.subscriptionStatus !== "active" &&
            !user.isBeta
        ) {
            LogRocket.init("acu09k/superhost-tools");
            LogRocket.identify(user._id, {
                email: user.username,
                name: user.firstName ? `${user.firstName} ${user.lastName}` : ""
                // // Add your own custom user variables here, ie:
                // subscriptionType: "pro"
            });

            hotjar.initialize(1683480, 6);
            hotjar.identify(user._id, {
                email: user.username,
                name: user.firstName ? `${user.firstName} ${user.lastName}` : ""
            });
        }
    }, [
        user._id,
        user.firstName,
        user.lastName,
        user.username,
        user.subscriptionStatus,
        user.isBeta,
        isAuthenticated
    ]);

    useEffect(() => {
        function identifyUser() {
            const {_id, createdAt, intercomHash, isBeta} = user;
            const identity = {
                app_id: "eagocqix",
                email: username,
                user_id: _id,
                created_at: createdAt,
                custom_data: {},
                user_hash: intercomHash,
                server: isBeta ? "beta" : "main",
                name: "",
                status: "",
                listings: 0
            };
            if (firstName) {
                identity.name = firstName;
            } else {
                identity.name = username;
            }
            if (lastName) {
                identity.name += ` ${lastName}`;
            }
            if (subscriptionStatus) {
                identity.status = isBeta ? "beta" : subscriptionStatus;
            }
            if (visibleListings.length) {
                identity.listings = visibleListings.length;
            }

            window.intercomSettings = identity;

            const w = window;
            const ic = w.Intercom;
            if (typeof ic === "function") {
                ic("reattach_activator");
                ic("update", w.intercomSettings);
            } else {
                const d = document;
                // eslint-disable-next-line no-var, prefer-rest-params, func-names, vars-on-top
                var i = function () {
                    // eslint-disable-next-line prefer-rest-params
                    i.c(arguments);
                } as any;
                i.q = [];
                // eslint-disable-next-line func-names
                i.c = function (args: any[]) {
                    i.q.push(args);
                };
                w.Intercom = i;
                // eslint-disable-next-line func-names
                const l = function () {
                    const s = d.createElement("script");
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = "https://widget.intercom.io/widget/eagocqix";
                    const x = d.getElementsByTagName("script")[0];
                    x?.parentNode?.insertBefore(s, x);
                };

                if (w.attachEvent) {
                    w.attachEvent("onload", l);
                } else {
                    w.addEventListener("load", l, false);
                }
            }
        }
        if (username) {
            identifyUser();
        }
    }, [firstName, lastName, listings, subscriptionStatus, user, username, visibleListings]);

    useEffect(() => {
        const query = new URLSearchParams(search).get("q");
        if (query) {
            setQuery(query);
        } else {
            setQuery("");
        }
    }, [search]);

    const bannerNotificationRenderer = useMemo(() => {
        if (showBannerAskNotification) {
            const handleAskPermission = () => {
                browserNotification?.requestPermission(() => {
                    setShowBannerAskNotification(false);
                });
            };

            const actionRenderer = (
                <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={handleAskPermission}
                >
                    Enable/Disable
                </button>
            );

            const handleCloseBanner = () => {
                setShowBannerAskNotification(false);
            };

            return (
                browserNotification?.shouldAskPermission() && (
                    <Banner
                        alertClass="alert-warning"
                        actionRenderer={actionRenderer}
                        onClose={handleCloseBanner}
                    >
                        <p className="card-text">
                            <strong>Browser Notifications.&nbsp;</strong>
                            Would you like message and reservation notifications in your browser?
                        </p>
                    </Banner>
                )
            );
        }

        return null;
    }, [showBannerAskNotification, browserNotification]);

    function handleShowAddAccount() {
        setShowAddAccountModal(true);
    }

    function handleCloseModal(isSuccess?: boolean) {
        setShowAddAccountModal(false);
        if (typeof isSuccess === "boolean" && isSuccess) {
            setShowBannerAskNotification(true);
        }
    }

    function handleCloseNav() {
        setShowProfileNav(false);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setQuery(event.target.value);
    }

    function handleChangeText(text: string) {
        setQuery(text);
    }

    async function handleSearch(event: React.SyntheticEvent) {
        event.preventDefault();
        history.push(`/inbox?q=${encodeURIComponent(query)}`);
    }

    function handleClearQuery() {
        setQuery("");
    }

    function handleContactMenu() {
        const intercom = window.Intercom;
        if (typeof intercom === "function") {
            intercom("show");
        }
    }

    const handleToglleMenuMobile = useCallback(() => {
        setShowModalMenuMobile(prev => !prev);
    }, []);

    const handleToggleNewUI = useCallback(() => {
        setNewUIContext(!isNewUIEnabled);
    }, [isNewUIEnabled, setNewUIContext]);

    const regexCalendar = /#!\/calendar\/(.*)/;
    const foundCalendar = location.hash.match(regexCalendar);
    if (foundCalendar) {
        return <Redirect to={`/pricing/${foundCalendar[1]}`} />;
    }
    const regexMessageRules = /#!\/messageRules\/(.*)/;
    const foundMessageRules = location.hash.match(regexMessageRules);
    if (foundMessageRules) {
        return <Redirect to={`/messaging/${foundMessageRules[1]}`} />;
    }
    const regexListings = /#!\/listings/;
    const foundListings = location.hash.match(regexListings);
    if (foundListings) {
        return <Redirect to="/listings" />;
    }
    const regexBilling = /#!\/billing/;
    const foundBilling = location.hash.match(regexBilling);
    if (foundBilling) {
        return <Redirect to="/billing" />;
    }
    const regexSettings = /#!\/settings/;
    const foundSettings = location.hash.match(regexSettings);
    if (foundSettings) {
        return <Redirect to="/settings" />;
    }

    const isCalendarActive = !!matchPath(pathname, {path: MULTICALENDAR_PATH, exact: true});
    const isInboxActive = !!matchPath(pathname, {path: INBOX_PATH, exact: true});
    const isMessagingActive = !!matchPath(pathname, {
        path: MESSAGING_PATH,
        exact: true
    });
    const isPricingActive = !!matchPath(pathname, {path: PRICING_PATH, exact: true});

    const searchElement = (
        <form className="search" onSubmit={event => handleSearch(event)}>
            <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={query}
                onChange={handleChange}
            />
            {!query && (
                <button type="button" className="btn" onClick={handleSearch}>
                    <FiSearch />
                </button>
            )}
            {!!query && (
                <button type="button" className="btn" onClick={handleClearQuery}>
                    <FiX />
                </button>
            )}
        </form>
    );

    let subscriptionBadge;
    // eslint-disable-next-line no-undef
    if (__IS_BETA__) {
        subscriptionBadge = isNewUIEnabled ? (
            <BadgeNB colorScheme="yellow" variant="solid">
                Beta
            </BadgeNB>
        ) : (
            <Badge variant="warning" className="ml-2">
                Beta
            </Badge>
        );
    } else if (subscriptionStatus === "trialing") {
        subscriptionBadge = isNewUIEnabled ? (
            <BadgeNB colorScheme="yellow" variant="solid">
                Trial
            </BadgeNB>
        ) : (
            <Badge variant="warning" className="ml-2">
                Trial
            </Badge>
        );
    }

    const profileNav = (
        <NavDropdown
            data-testid="profile-nav"
            id="profile-nav"
            alignRight
            title={
                <img
                    src={userImage}
                    alt={`${firstName} ${lastName} Avatar`}
                    className="user-image"
                />
            }
            className="ml-2"
            show={showProfileNav}
            onToggle={showProfileNav => {
                setShowProfileNav(showProfileNav);
            }}
        >
            <div className="d-md-none pl-2 pr-2 pt-2 d-flex">
                <button
                    className="az-header-arrow btn bg-white pd-l-10 pd-0"
                    type="button"
                    onClick={handleCloseNav}
                >
                    <FiChevronLeft />
                </button>
            </div>
            <div className="az-header-profile">
                <div className="az-img-user">
                    <img src={userImage} alt="" />
                </div>
                <h6 className="text-break">{`${firstName || ""} ${lastName || ""}`}</h6>
                <span>{subscriptionStatus}</span>
            </div>

            <div className="d-block d-lg-none pd-y-8 pd-x-15">{searchElement}</div>
            {hasPermissionEditUsers && (
                <NavDropdown.Item as={Link} to="/users" className="d-flex align-items-center">
                    <FiUser className="mr-1" />
                    Users
                </NavDropdown.Item>
            )}
            <NavDropdown.Item as={Link} to="/settings" className="d-flex align-items-center">
                <FiSettings className="mr-1" />
                Settings
            </NavDropdown.Item>
            {hasPermissionEditBillings && (
                <NavDropdown.Item as={Link} to="/billing" className="d-flex align-items-center">
                    <FiCreditCard className="mr-1" />
                    Billing
                </NavDropdown.Item>
            )}
            <NavDropdown.Item
                onClick={() => {
                    window.open("https://help.hosttools.com/", "_blank");
                }}
                className="d-flex align-items-center"
            >
                <FiHelpCircle className="mr-1" />
                F.A.Q.
            </NavDropdown.Item>
            {hasPermissionEditAccounts && (
                <NavDropdown.Item
                    onClick={handleShowAddAccount}
                    className="d-flex align-items-center"
                >
                    <FiPlus className="mr-1" />
                    Link Account
                </NavDropdown.Item>
            )}
            <NavDropdown.Item onClick={handleContactMenu} className="d-flex align-items-center">
                <FiMessageCircle className="mr-1" />
                Support
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/logout" className="d-flex align-items-center">
                <FiExternalLink className="mr-1" />
                Sign Out
            </NavDropdown.Item>
        </NavDropdown>
    );

    if (!isAuthenticated) {
        return <div />;
    }

    return (
        <>
            {__IS_BETA__ && (
                <Box
                    position="fixed"
                    width="100vw"
                    top={0}
                    flexDir="row"
                    alignItems="center"
                    justifyContent="center"
                    bg="blue.600"
                    height={`${TOP_NEW_UI_BANNER_HEIGHT}px`}
                    zIndex={999}
                >
                    <Pressable
                        _hover={{textDecoration: "underline"}}
                        fontWeight="bold"
                        onPress={handleToggleNewUI}
                    >
                        <Text size="xs" color="white">
                            {isNewUIEnabled
                                ? "Miss the old user interface? Click here to switch back."
                                : "Click here to try the new Host Tools user interface!"}
                        </Text>
                    </Pressable>
                </Box>
            )}
            {isNewUIEnabled ? (
                <>
                    <Box
                        height={TOP_MENU_HEIGHT_DESKTOP}
                        position="fixed"
                        width="100vw"
                        zIndex={1}
                        top={`${TOP_NEW_UI_BANNER_HEIGHT}px`}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        bgColor="white"
                        borderBottomColor="blueGray.200"
                        borderBottomWidth={1}
                        borderBottomStyle="solid"
                        px={[4, 7]}
                    >
                        <Box
                            display="flex"
                            height="100%"
                            width="50%"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            px={[0, 2]}
                        >
                            <HStack alignItems="center">
                                <MobileView>
                                    <IconButton
                                        onPress={handleToglleMenuMobile}
                                        _icon={{color: "blueGray.400"}}
                                        icon={<HamburgerIcon />}
                                    />
                                </MobileView>
                                <Link to="/">
                                    <Image
                                        source={logoSource}
                                        height={[22, 30]}
                                        width={[110, 150]}
                                        alt="Host Tools Logo"
                                    />
                                </Link>
                            </HStack>
                            <DesktopView height="100%" flexDirection="row" alignItems="center">
                                <MenuLink to={MULTICALENDAR_PATH} isActive={isCalendarActive}>
                                    Calendar
                                </MenuLink>

                                {hasPermissionEditInbox && (
                                    <MenuLink to="/inbox" isActive={isInboxActive}>
                                        Inbox
                                    </MenuLink>
                                )}

                                {hasPermissionEditMessageRules && (
                                    <NavMessaging
                                        isActive={!!isMessagingActive}
                                        listingGroups={listingGroups}
                                        visibleListings={visibleListings}
                                    />
                                )}
                                {hasPermissionEditPricing && (
                                    <NavPricing
                                        isActive={!!isPricingActive}
                                        visibleListings={visibleListings}
                                    />
                                )}
                            </DesktopView>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {subscriptionStatus !== "active" &&
                                subscriptionStatus !== "canceled" && <QuickGuideButton />}
                            <Box p={2}>{subscriptionBadge}</Box>

                            <DesktopView p={2}>
                                <form className="search" onSubmit={handleSearch}>
                                    <Input
                                        variant="filled"
                                        placeholder="Search..."
                                        bgColor="blueGray.100"
                                        color="blueGray.400"
                                        size="md"
                                        value={query}
                                        onChangeText={handleChangeText}
                                        InputLeftElement={inputLeftElement}
                                        InputRightElement={inputRightElement}
                                    />
                                </form>
                            </DesktopView>
                            <Box p={2}>
                                <UILink href="https://help.hosttools.com/" isExternal>
                                    <QuestionIcon color="blueGray.400" />
                                </UILink>
                            </Box>
                            <Box p={2}>
                                <Menu
                                    minWidth={64}
                                    trigger={triggerUserProfile}
                                    placement="bottom right"
                                    bg="white"
                                >
                                    <Box px={4} py={1}>
                                        <MenuProfile
                                            onAddAccount={handleShowAddAccount}
                                            onSupport={handleContactMenu}
                                        />
                                    </Box>
                                </Menu>
                            </Box>
                        </Box>
                    </Box>
                    {showAddAccountModal && (
                        <ModalAddAccount show={showAddAccountModal} onHide={handleCloseModal} />
                    )}
                    {showModalMenuMobile && (
                        <ModalMenuMobile
                            isOpen={showModalMenuMobile}
                            onHide={handleToglleMenuMobile}
                            onAddAccount={handleShowAddAccount}
                            onSupport={handleContactMenu}
                        />
                    )}
                    {bannerNotificationRenderer}
                </>
            ) : (
                <>
                    <div className="az-header" style={{top: TOP_NEW_UI_BANNER_HEIGHT}}>
                        <div className="container">
                            <Navbar bg="transparent" expand="sm" className="flex-grow-1">
                                <Navbar.Brand as={Link} to="/" data-testid="site-logo">
                                    <img
                                        src={logoBlack}
                                        height="30"
                                        className="d-none d-sm-block"
                                        alt="Host Tools Logo"
                                    />
                                    <img
                                        src={logoSquare}
                                        height="30"
                                        className="d-block d-sm-none"
                                        alt="Host Tools Logo"
                                    />
                                </Navbar.Brand>
                                <Nav className="flex-grow-1">
                                    <>
                                        {!!user._id && (
                                            <Nav.Link as={Link} to="/" className="mr-3">
                                                <FiCalendar className="d-block d-sm-none" />
                                                <span className="d-none d-sm-block">Calendar</span>
                                            </Nav.Link>
                                        )}
                                        {hasPermissionEditInbox && (
                                            <Nav.Link as={Link} to="/inbox" className="mr-3">
                                                <FiMessageSquare className="d-block d-sm-none" />
                                                <span className="d-none d-sm-block">Inbox</span>
                                            </Nav.Link>
                                        )}
                                        {hasPermissionEditMessageRules && (
                                            <NavMessaging
                                                visibleListings={visibleListings}
                                                listingGroups={listingGroups}
                                            />
                                        )}
                                        {hasPermissionEditPricing && (
                                            <NavPricing visibleListings={visibleListings} />
                                        )}
                                    </>
                                    <span className="d-flex align-items-center ml-auto">
                                        {subscriptionBadge}
                                        <div className="d-none d-lg-block ml-2">
                                            {searchElement}
                                        </div>
                                        <a
                                            href="https://help.hosttools.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="d-none d-lg-block ml-2 nav-link"
                                        >
                                            <FiHelpCircle className="tx-18" />
                                        </a>
                                        {profileNav}
                                    </span>
                                </Nav>
                            </Navbar>
                        </div>
                        {showAddAccountModal && (
                            <ModalAddAccount show={showAddAccountModal} onHide={handleCloseModal} />
                        )}
                    </div>
                    {bannerNotificationRenderer}
                </>
            )}
        </>
    );
};

export default Header;
