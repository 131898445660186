import {Dispatch, SetStateAction, createContext} from "react";
import {type Permissions} from "@hosttools/core/shared/utils/utils.permission";

import type {ErrorsType} from "../../utils/errors";
import {Lock} from "../../models/lock";
import {ListingGroup} from "../../models/listingGroup";
import {User, UserRaw} from "../../models/user";
import {Listing} from "../../models/listing";
import {type AccountCustom} from "../../utils/account";

export interface UserContextType {
    isLoading: boolean;
    isAuthenticated: boolean;
    isLoadingAccounts: boolean;
    user: User;
    accounts: AccountCustom[];
    listings: Listing[];
    visibleListings: Listing[];
    listingGroups: ListingGroup[];
    permissions: Permissions;
    locks: Lock[];
    seamToken: string;
    errors: ErrorsType;
    getListing(id: string): Listing | undefined;
    updateUser(): Promise<void>;
    setUser: (payload: Partial<UserRaw>) => void;
    setState: Dispatch<SetStateAction<UserContextType>>;
    logout(): Promise<void>;
}

export const defaultValue = {
    isLoading: true,
    isAuthenticated: false,
    isLoadingAccounts: false,
    user: new User({
        _id: "",
        firstName: "",
        lastName: "",
        username: "",
        subscriptionStatus: "",
        isBeta: false,
        intercomHash: "",
        trialLengthEndDate: undefined,
        createdAt: ""
        // tags: [],
        // locks: [],
        // accounts: [],
        // listingGroups: []
        // apiKeys: undefined,
        // cancellation: {}
    } as UserRaw),
    seamToken: "",
    accounts: [],
    listings: [],
    visibleListings: [],
    listingGroups: [],
    locks: [],
    permissions: {},
    errors: {},
    logout: () => Promise.resolve(),
    getListing: () => undefined,
    setUser: () => undefined,
    setState: () => {},
    updateUser: () => Promise.resolve()
} as UserContextType;

export const UserContext = createContext<UserContextType>(defaultValue);
