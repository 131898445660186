import {useContext, useEffect, useState} from "react";

import WebSocketContext from "../../contexts/WebSocketContext";
import HTTPContext from "../../contexts/HTTPContext";
import {updateListWS} from "../../../utils";
import {Account, sanitizeAccount} from "../../../models/account";
import UserService from "../../../services/user";

export const useWatchAccount = (userId?: string, loadOnMount = true) => {
    const http = useContext(HTTPContext);
    const websocket = useContext(WebSocketContext);
    const [accounts, setAccounts] = useState<Account[]>();

    useEffect(() => {
        async function init() {
            const userService = new UserService(http);
            const accounts = await userService.fetchAccounts();
            if (accounts) {
                setAccounts(accounts);
            }
        }

        if (userId && loadOnMount) {
            init();
        }
    }, [userId, loadOnMount, http]);

    useEffect(() => {
        function syncAccounts(payload: WSPayload<Account>) {
            setAccounts(prev => {
                if (!prev && (payload.event === "update" || payload.event === "delete")) {
                    return;
                }
                const accounts = updateListWS(prev ?? [], payload, sanitizeAccount);
                return accounts;
            });
        }

        websocket?.on<WSRoom>("account", syncAccounts);
        return () => {
            websocket?.off("account", syncAccounts);
        };
    }, [websocket]);

    return {
        accounts,
        setAccounts
    };
};
