import {Listing} from "../models/listing";
import {Account} from "../models/account";
import {Lock} from "../models/lock";

export type AccountCustom<T extends Account["type"] = Account["type"]> = Account & {
    type: T;
    logo?: string;
    listings: Listing[];
    locks: Lock[];
};

export function mapListings2Accounts<T extends AccountCustom["type"]>(
    accounts: AccountCustom<T>[],
    allListings: Listing[]
) {
    return accounts.map<AccountCustom<T>>(account => {
        const listings = allListings.reduce((result, listing) => {
            if (listing.accountID !== account._id) {
                return result;
            }
            let linkedListings: Listing[];
            if (listing.linkedListingID) {
                // Find parent listing
                linkedListings = allListings.filter(parentListing => {
                    return listing.linkedListingID === parentListing._id;
                });
                if (linkedListings && linkedListings.length)
                    listing.listingEnabled = linkedListings[0].listingEnabled;
            } else {
                linkedListings = allListings.filter(linkedListing => {
                    return listing._id === linkedListing.linkedListingID;
                });
            }
            // Fill out some information for listing. Some function will no longer without these info
            // Make sure listingEnabled returns true or false because on
            // old accounts, listingEnabled can be null when the listing is enabled
            listing.listingEnabled = listing.listingEnabled !== false;
            listing.linkedListingCount = linkedListings.length;
            listing.airbnbUserID = account.airbnbUserID;

            result.push(listing);

            return result;
        }, [] as Listing[]);

        return {
            ...account,
            locks: "locks" in account ? account.locks : [],
            listings
        };
    });
}

export function buildLocks2Accounts<T extends AccountCustom["type"]>(
    accounts: AccountCustom<T>[],
    locks: Lock[]
) {
    return accounts.map(account => {
        const result = locks.reduce((result, lock) => {
            if (account._id === lock.accountID) {
                result.push(lock);
            }
            return result;
        }, [] as Lock[]);

        return {
            ...account,
            listings: "listings" in account ? account.listings : [],
            locks: result
        } as AccountCustom<T>;
    });
}

export function setListingsIntoAccounts(accounts: Account[], allListings: Listing[]) {
    return accounts.map<AccountCustom>(account => {
        const listings = allListings.reduce((result, listing) => {
            if (listing.accountID !== account._id) {
                return result;
            }
            let linkedListings: Listing[];
            if (listing.linkedListingID) {
                // Find parent listing
                linkedListings = allListings.filter(parentListing => {
                    return listing.linkedListingID === parentListing._id;
                });
                if (linkedListings && linkedListings.length)
                    listing.listingEnabled = linkedListings[0].listingEnabled;
            } else {
                linkedListings = allListings.filter(linkedListing => {
                    return listing._id === linkedListing.linkedListingID;
                });
            }
            // Fill out some information for listing. Some function will no longer without these info
            // Make sure listingEnabled returns true or false because on
            // old accounts, listingEnabled can be null when the listing is enabled
            listing.listingEnabled = listing.listingEnabled !== false;
            listing.linkedListingCount = linkedListings.length;
            listing.airbnbUserID = account.airbnbUserID;

            result.push(listing);

            return result;
        }, [] as Listing[]);

        return {
            ...account,
            locks: [],
            listings
        };
    });
}

export function getAccountID(account: Account): string | undefined {
    return (
        (account.type === "Airbnb" && account.airbnbUserID) ||
        (account.type === "Booking" && account?.booking.propertyID) ||
        undefined
    );
}
