import {AccountType, channels, integrations} from "@hosttools/core/constant";
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import {FaCircleNotch} from "react-icons/fa";
import {buildChannel, ChannelObject} from "@hosttools/frontend/models/channel";
import {Box, Button, HStack, Text, VStack, View} from "native-base";

import {AIRBNB_URL} from "../../constant";
import {UserContext} from "../../providers/UserProvider";
import SocialValidation from "../SocialValidation";

import Channels, {ConnectedChannels} from "./Channels";
import Code from "./Code";
import Credentials from "./Credentials";
import Verification from "./Verification";
import type {CredentialsType, Errors, Method} from "./types";

import useNewDesign from "@/client/hooks/useNewDesign";
import ModalV2 from "@/client/components/Modal";
import useMobileView from "@/client/hooks/useMobileView";
import DesktopView from "@/client/components/DesktopView";

export type Props = {
    show: boolean;
    channel?: AccountType;
    username?: string;
    accountID?: string;
    isLock?: boolean;
    hideCloseButtons?: boolean;
    hideSocial?: boolean;
    onHide: (isSuccess?: boolean) => void;
};

type TypeName = "credentials" | "channels" | "verification" | "code" | "success";

type DataResponse = {
    type?: TypeName;
    session?: any;
    error?: string;
    methods: Method[];
    info?: string;
    errorCode?: string;
};

const AddAccount: React.FC<Props> = props => {
    const {
        show,
        channel: initChannel,
        username = "",
        isLock,
        hideCloseButtons = false,
        hideSocial,
        accountID = "",
        onHide
    } = props;
    const isNewUIEnabled = useNewDesign();
    const isMobile = useMobileView();
    const {accounts, updateUser} = useContext(UserContext);
    const [channel, setChannel] = useState<Exclude<ChannelObject, {value: "internal"}>>();

    const [credentials, setCredentials] = useState<CredentialsType>({});
    const [verificationIndex, setVerificationIndex] = useState(0);
    const [methods, setMethods] = useState<any[]>([]);
    const [code, setCode] = useState<string | undefined>();

    const [connectedChannels, setConnectedChannels] = useState<ConnectedChannels>();
    const [disconnectedChannels, setDisconnectedChannels] = useState<ConnectedChannels>();
    const alreadyConnected = useMemo(
        () => connectedChannels && Object.keys(connectedChannels).length > 0,
        [connectedChannels]
    );

    const [session, setSession] = useState({});
    const [type, setType] = useState<TypeName>();
    const [errors, setErrors] = useState<Errors>({});
    const [info, setInfo] = useState<string>("");
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const [buttonText, setButtonText] = useState("");
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        const channel = initChannel ? buildChannel(initChannel) : undefined;
        setChannel(channel);
        setCredentials({
            username: username || "",
            password: "",
            apiKey: "",
            propertyID: ""
        });
        setType(channel ? "credentials" : "channels");
        setErrors({});
        setInfo("");
        setMethods([]);
        setVerificationIndex(0);
        setCode(undefined);
        setShowSpinner(false);
    }, [show, username, initChannel]);

    useEffect(() => {
        if (type === "credentials") {
            setButtonText("Add Account");
            setTitle(`Connect ${channel?.label} Account`);
        } else if (type === "verification") {
            setButtonText("Send Verification");
            setTitle(`Verify ${channel?.label} Account`);
        } else if (type === "code") {
            setButtonText("Submit Code");
            setTitle(`Verify ${channel?.label} Account`);
        } else if (type === "channels" && isNewUIEnabled) {
            setButtonText("Continue");
            setTitle("What account would you like help with host tools?");
        } else {
            setButtonText("Continue");
            setTitle("Connect Account");
        }
    }, [channel, type, isNewUIEnabled]);

    useEffect(() => {
        setConnectedChannels(
            accounts.reduce((connectedChannels, account) => {
                if (account.lastLoginAttemptSuccessful) {
                    connectedChannels[account.type] = (connectedChannels[account.type] ?? 0) + 1;
                }
                return connectedChannels;
            }, {} as ConnectedChannels)
        );
        setDisconnectedChannels(
            accounts.reduce((connectedChannels, account) => {
                if (account.lastLoginAttemptSuccessful === false) {
                    connectedChannels[account.type] = (connectedChannels[account.type] ?? 0) + 1;
                }
                return connectedChannels;
            }, {} as ConnectedChannels)
        );
    }, [accounts]);

    const hideAddAccount = useCallback(
        async (isSuccess: boolean) => {
            try {
                // Needs to be === true because sometimes isSuccess can be an event object which we don't want
                if (isSuccess === true) {
                    const tagManagerArgs = {
                        dataLayer: {
                            event: `${channel?.label} Added Event`,
                            category: channel?.value?.toLowerCase(),
                            action: "account",
                            label: "added",
                            value: 0
                        }
                    };
                    TagManager.dataLayer(tagManagerArgs);

                    ReactPixel.fbq("track", `${channel?.value}AccountAdded`, {
                        currency: "USD",
                        value: "0.00"
                    });
                }
                onHide(isSuccess);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error("error", error);
            }
        },
        [channel, onHide]
    );

    const handleResponse = useCallback(
        async (data: DataResponse) => {
            const {type, session, error, methods, info, errorCode} = data;
            setSession(session);
            setErrors({error: error || "", errorCode: errorCode || ""});
            setInfo(info || "");

            if (type === "success") {
                if (accountID) {
                    hideAddAccount(true);
                    return;
                }
                setType("channels");
                return;
            }

            if (methods) {
                setMethods(methods);
            }
            setType(type);
        },
        [accountID, hideAddAccount]
    );

    const selectChannel = useCallback((channel: AccountType) => {
        const obj = buildChannel(channel);
        setChannel(obj);
        setErrors({});
        setType("credentials");
    }, []);

    const handleChangeCredentials = useCallback((newCredentials: CredentialsType) => {
        setCredentials(credentials => {
            return {...credentials, ...newCredentials};
        });
    }, []);

    const validateCredentials = useCallback(() => {
        const errors: Errors = {};
        let formIsValid = true;
        const {value, label} = channel ?? {};
        if (value === "Airbnb" || value === "HomeAway" || value === "August") {
            if (!credentials.username) {
                formIsValid = false;
                errors.username = `Please enter your ${label} username.`;
            } else {
                const re = /\S+@\S+\.\S+/;
                const validEmail = re.test(credentials.username);
                if (!validEmail) {
                    formIsValid = false;
                    errors.username = "Please enter a valid email address.";
                }
            }
            if (!credentials.password || credentials.password.length === 0) {
                formIsValid = false;
                errors.password = `Please enter your ${label} password.`;
            }
        } else if (value === "Houfy") {
            if (!credentials.apiKey || credentials.apiKey.length === 0) {
                formIsValid = false;
                errors.apiKey = `Please enter your ${label} API Key.`;
            }
        } else if (!credentials.propertyID || credentials.propertyID.length === 0) {
            formIsValid = false;
            errors.propertyID = `Please enter your ${label} Property ID.`;
        }
        setErrors(errors);
        return formIsValid;
    }, [
        channel,
        credentials.apiKey,
        credentials.password,
        credentials.propertyID,
        credentials.username
    ]);

    const submitAddAccount = useCallback(async () => {
        if (showSpinner) {
            return;
        }
        if (validateCredentials() && channel) {
            setShowSpinner(true);
            setErrors({});
            const url = "/addAccount";
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    // use label for data sounds nonsense
                    body: JSON.stringify({type, channel: channel.value, credentials, accountID})
                });

                if (response.ok) {
                    const data = await response.json();
                    handleResponse(data);
                } else {
                    const data = await response.json();
                    const errors = {error: data.message};
                    setErrors(errors);
                }
            } catch {
                setErrors({error: "There was a issue adding account."});
            } finally {
                setShowSpinner(false);
            }
        }
    }, [accountID, channel, credentials, handleResponse, showSpinner, type, validateCredentials]);

    const getSeamURL = useCallback(async () => {
        const url = "/getSeamURL";
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({type, channel: channel?.value, accountID})
            });

            if (response.ok) {
                const data = await response.json();
                return data.seamURL;
            }
            const data = await response.json();
            const errors = {error: data.message};
            setErrors(errors);
            return null;
        } catch {
            setErrors({error: "There was a issue adding account."});
            return null;
        }
    }, [accountID, channel, type]);

    const handleChangeVerification = useCallback((verificationIndex: number) => {
        setVerificationIndex(verificationIndex);
    }, []);

    const validateVerificationIndex = useCallback(() => {
        const errors: Errors = {};
        let formIsValid = true;
        if (!verificationIndex && verificationIndex !== 0) {
            formIsValid = false;
            errors.error = "Please select a verification phone number";
        }
        setErrors(errors);
        return formIsValid;
    }, [verificationIndex]);

    const submitVerification = useCallback(async () => {
        if (showSpinner) {
            return;
        }
        if (validateVerificationIndex()) {
            setErrors({});
            setShowSpinner(true);
            const url = "/verifyAccount";
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        type,
                        channel: channel?.label,
                        session,
                        index: verificationIndex,
                        credentials,
                        accountID
                    })
                });
                if (response.ok) {
                    const data = await response.json();
                    handleResponse(data);
                } else {
                    const data = await response.json();
                    const errors = {error: data.message};
                    setErrors(errors);
                }
            } catch {
                setErrors({error: "There was a issue verifying the account."});
            }
        }
        setShowSpinner(false);
    }, [
        accountID,
        channel,
        credentials,
        handleResponse,
        session,
        showSpinner,
        type,
        validateVerificationIndex,
        verificationIndex
    ]);

    const validateCode = useCallback(() => {
        const errors: Errors = {};
        let formIsValid = true;
        if (!code) {
            formIsValid = false;
            errors.verificationCode = "Please enter the verification code that was sent to you.";
        }
        setErrors(errors);
        return formIsValid;
    }, [code]);

    const submitCode = useCallback(async () => {
        if (showSpinner) {
            return;
        }
        if (validateCode()) {
            setErrors({});
            setShowSpinner(true);
            const url = "/verifyAccount";
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        type,
                        channel: channel?.label,
                        session,
                        code,
                        credentials,
                        accountID
                    })
                });

                if (response.ok) {
                    const data = await response.json();
                    handleResponse(data);
                    await updateUser();
                } else {
                    const data = await response.json();
                    const errors = {error: data.message};
                    setErrors(errors);
                }
            } catch {
                setErrors({error: "There was a issue verifying the account."});
            }
        }
        setShowSpinner(false);
    }, [
        accountID,
        channel,
        code,
        credentials,
        handleResponse,
        session,
        showSpinner,
        type,
        updateUser,
        validateCode
    ]);

    const handleBack = useCallback(() => {
        setErrors({});
        setType("channels");
        setChannel(undefined);
    }, []);

    const handleSubmitCredentitals = useCallback(async () => {
        if (channel?.value === "Airbnb") {
            setShowSpinner(true);
            const url = AIRBNB_URL;
            window.open(url, "_self");
        } else if (channel?.value === "Seam") {
            setShowSpinner(true);
            const url = await getSeamURL();
            if (url) {
                window.open(url, "_self");
            }
        } else {
            submitAddAccount();
        }
    }, [channel?.value, getSeamURL, submitAddAccount]);

    const handleSubmit = useCallback(async () => {
        if (type === "credentials") {
            handleSubmitCredentitals();
        } else if (type === "verification") {
            submitVerification();
        } else if (type === "code") {
            submitCode();
        }
    }, [type, handleSubmitCredentitals, submitVerification, submitCode]);

    const handleCancel = useCallback(() => {
        hideAddAccount(false);
    }, [hideAddAccount]);

    if (isNewUIEnabled) {
        const Footer = (
            <>
                {type === "channels" && (
                    <DesktopView flexDir="row" justifyContent="flex-end">
                        <>
                            {!hideCloseButtons && (
                                <Button
                                    display={["none", "flex"]}
                                    type="button"
                                    mt={6}
                                    variant="outline"
                                    onPress={handleCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                        </>
                    </DesktopView>
                )}
                {type !== "channels" && type !== "credentials" && (
                    <View flexDir="row" justifyContent="flex-end">
                        <Button
                            type="button"
                            className="btn btn-outline-primary"
                            isLoading={showSpinner}
                            isLoadingText={buttonText}
                            onPress={handleSubmit}
                        >
                            {buttonText}
                        </Button>
                    </View>
                )}
            </>
        );

        return (
            <ModalV2
                isOpen={show}
                title={(hideSocial && !isMobile) || (isMobile && type === "channels") ? title : ""}
                btnTextCancel={null}
                btnTextOk={null}
                size={hideSocial ? "lg" : "xl"}
                onClose={hideCloseButtons ? undefined : onHide}
            >
                <HStack minH={["unset", hideSocial ? "unset" : "500px"]}>
                    {!hideSocial && (
                        <DesktopView width="50%" pr={6}>
                            <Box
                                p={4}
                                borderRadius={16}
                                height="full"
                                bg={{
                                    linearGradient: {
                                        colors: [
                                            "rgba(54, 189, 182, 0.1)",
                                            "rgba(251, 179, 21, 0.1)"
                                        ],
                                        start: [0, 0],
                                        end: [1, 1]
                                    }
                                }}
                            >
                                <SocialValidation />
                            </Box>
                        </DesktopView>
                    )}

                    <VStack
                        width={["full", hideSocial ? "full" : "50%"]}
                        pt={[5, 0]}
                        px={[5, 0]}
                        justifyContent="space-between"
                        space={5}
                    >
                        <VStack>
                            {!hideSocial && (
                                <Text
                                    display={[
                                        type === "code" || type === "verification"
                                            ? "block"
                                            : "none",
                                        "block"
                                    ]}
                                    fontWeight="bold"
                                    size="2xl"
                                    mb={5}
                                >
                                    {title}
                                </Text>
                            )}
                            {type === "channels" && (
                                <Channels
                                    channels={isLock ? integrations : channels}
                                    disconnectedChannels={disconnectedChannels}
                                    connectedChannels={connectedChannels}
                                    errors={errors}
                                    hideSocial={hideSocial}
                                    onSelectChannel={selectChannel}
                                />
                            )}
                            {type === "credentials" && channel && (
                                <Credentials
                                    channel={channel.value}
                                    credentials={credentials}
                                    info={info}
                                    errors={errors}
                                    showSpinner={showSpinner}
                                    onChange={handleChangeCredentials}
                                    onSubmitCredentials={handleSubmitCredentitals}
                                    onCancel={handleBack}
                                />
                            )}
                            {type === "verification" && channel && (
                                <Verification
                                    methods={methods}
                                    channel={channel}
                                    info={info}
                                    errors={errors}
                                    onChange={handleChangeVerification}
                                />
                            )}
                            {type === "code" && channel && (
                                <Code
                                    channel={channel}
                                    method={methods[verificationIndex].type}
                                    info={info}
                                    errors={errors}
                                    onChange={setCode}
                                />
                            )}
                        </VStack>
                        {Footer}
                    </VStack>
                </HStack>
            </ModalV2>
        );
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" backdrop="static">
            <form>
                <Modal.Header closeButton={!hideCloseButtons}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pd-0">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="pd-20 pd-sm-40">
                                {type === "channels" && (
                                    <Channels
                                        channels={isLock ? integrations : channels}
                                        disconnectedChannels={disconnectedChannels}
                                        connectedChannels={connectedChannels}
                                        errors={errors}
                                        onSelectChannel={selectChannel}
                                    />
                                )}
                                {type === "credentials" && channel && (
                                    <Credentials
                                        channel={channel.value}
                                        credentials={credentials}
                                        info={info}
                                        errors={errors}
                                        showSpinner={showSpinner}
                                        onChange={handleChangeCredentials}
                                    />
                                )}
                                {type === "verification" && channel && (
                                    <Verification
                                        methods={methods}
                                        channel={channel}
                                        info={info}
                                        errors={errors}
                                        onChange={handleChangeVerification}
                                    />
                                )}
                                {type === "code" && channel && (
                                    <Code
                                        channel={channel}
                                        method={methods[verificationIndex].type}
                                        info={info}
                                        errors={errors}
                                        onChange={setCode}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-4 d-none d-md-flex">
                            <div className="d-flex flex-row bg-light">
                                <SocialValidation />
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-between wd-100p min-h-38px">
                        {type === "credentials" &&
                            (channel?.value === "August" ? (
                                <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={() => {
                                        hideAddAccount(true);
                                    }}
                                >
                                    Cancel
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={handleBack}
                                >
                                    Back
                                </button>
                            ))}

                        {type === "channels" && (
                            <>
                                {!hideCloseButtons && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-dark"
                                        onClick={() => {
                                            hideAddAccount(true);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                )}
                                {alreadyConnected && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={() => {
                                            hideAddAccount(true);
                                        }}
                                    >
                                        Continue
                                    </button>
                                )}
                            </>
                        )}

                        {type !== "channels" && (
                            <>
                                {/*  This div to make button align right */}
                                <div />
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={handleSubmit}
                                >
                                    {showSpinner && (
                                        <FaCircleNotch
                                            className="fa-spin mr-1"
                                            data-testid="spinner"
                                        />
                                    )}
                                    {buttonText}
                                </button>
                            </>
                        )}
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default memo(AddAccount);
