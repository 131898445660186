import React, {memo} from "react";
import {Box, Stack} from "native-base";
import {Listing} from "@hosttools/frontend/models/listing";
import {ListingGroup} from "@hosttools/frontend/models/listingGroup";

import ListingGroupsMenu from "./ListingGroupsMenu";
import ListingsMenu from "./ListingsMenu";

type Props = {visibleListings: Listing[]; listingGroups: ListingGroup[]};

const MessagingMenu: React.FC<Props> = ({visibleListings, listingGroups}) => {
    return (
        <Stack space={5} direction={["column", "row"]} px={[0, 3]} py={[0, 1]}>
            <Box maxW={["full", 64]} maxH={["full", 96]} p={3} overflowY="auto">
                <ListingGroupsMenu
                    listingGroups={listingGroups}
                    showAllListing={visibleListings.length > 1}
                />
            </Box>
            <Box
                py={4}
                px={5}
                borderRadius={10}
                bg="blueGray.50"
                borderWidth={1}
                borderStyle="solid"
                borderColor="blueGray.200"
                maxW={["full", 64]}
                maxH={["full", 96]}
                overflowY="auto"
            >
                <ListingsMenu listings={visibleListings} to="messaging" />
            </Box>
        </Stack>
    );
};

export default memo(MessagingMenu);
