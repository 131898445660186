import classNames from "classnames";
import React, {FC, ForwardedRef, forwardRef, memo, ReactNode} from "react";
import {FaCircleNotch} from "react-icons/fa";
import {Spinner, View, Text} from "native-base";
import {IViewProps} from "native-base/lib/typescript/components/basic/View/types";

import useNewDesign from "@/client/hooks/useNewDesign";

export type Props = IViewProps & {
    className?: string;
    /**
     * this requires parent element has to be `{flex: 1}`
     */
    center?: boolean;
    children?: ReactNode;
    forwardedRef?: ForwardedRef<HTMLDivElement>;
};

const Loader: FC<Props> = ({
    className = "",
    children = "Loading...",
    center,
    forwardedRef,
    ...restProps
}) => {
    const isNewUpdatedUI = useNewDesign();

    if (isNewUpdatedUI) {
        const SpinnerNode = (
            <>
                <Spinner mr="2px" />
                <Text size="sm">{` ${children}`}</Text>
            </>
        );

        const centerProps: IViewProps = {
            width: "100%",
            height: "100%",
            flexGrow: 1,
            flexShrink: 1
        };

        return (
            <View
                ref={forwardedRef}
                testID="loader"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                {...(center ? centerProps : {})}
                {...restProps}
            >
                {SpinnerNode}
            </View>
        );
    }

    const actualClassNames = classNames(className, {
        "d-flex flex-1 justify-content-center align-items-center": center
    });

    return (
        <div ref={forwardedRef} className={actualClassNames} data-testid="loader">
            <FaCircleNotch className="fa-spin mr-1" />
            {` ${children}`}
        </div>
    );
};

Loader.displayName = "Loader";

const FowardRefComponent = forwardRef<HTMLDivElement, Props>((props, ref) => (
    <Loader {...props} forwardedRef={ref} />
));

FowardRefComponent.displayName = `forwardedRef(${Loader.displayName})`;

export default memo(FowardRefComponent);
