import {
    Listing as ListingModel,
    ListingCalendar as ListingCalendarModel
} from "@hosttools/core/generated/models";
import {getListingName} from "@hosttools/core/shared/utils/utils.listing";

export type ListingCalendar = MongooseModel2Client<ListingCalendarModel>;
export type ListingRaw = MongooseModel2Client<ListingModel>;

// TODO convert to class
export type Listing = Omit<ListingRaw, "airbnbTimeZone"> & {
    name: string;
    airbnbName: string;
    /**
     * @deprecated use `timeZone` instead
     */
    airbnbTimeZone: string;
    timeZone: string;
    currencySymbol: string;
    linkedListingCount: number;
    airbnbUserID?: string;
    linkedListings?: Listing[];
};

export type ListingChannel = Listing["source"];

export function sanitizeListing(listing: Listing): Listing {
    listing.airbnbName = listing.airbnbName ?? "";

    if (!listing.airbnbTimeZone) {
        const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
        listing.airbnbTimeZone = timeZone;
    }

    listing.timeZone = listing.airbnbTimeZone;
    listing.name = getListingName(listing);
    listing.currencySymbol = listing.currencySymbol || "";

    return listing;
}

export function sanitizeListings(listings: Listing[]): Listing[] {
    return listings.map(sanitizeListing);
}
