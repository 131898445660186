import isEqual from "lodash/isEqual";
import {LeanDocument} from "mongoose";

import {UserDocument} from "../../generated/models";
import {PermissionKey, Permissions as PermissionsUser, PermissionValue, Role} from "../model/user";

export {Role};

export const roles: Role[] = ["assistant", "owner", "cleaner"];
export const rolesLabel: Record<Role, string> = {
    manager: "Manager/Admin",
    assistant: "Assistant",
    owner: "Owner",
    cleaner: "Cleaner"
};

export const mapRolePermissions: Record<Role, PermissionsUser> = {
    manager: {
        inbox: "edit",
        messageRules: "edit",
        pricing: "edit",
        availability: "edit",
        accounts: "edit",
        listings: "edit",
        listingGroups: "edit",
        reservationFull: "edit",
        settings: "edit",
        billing: "edit",
        userManagement: "edit",
        locks: "edit"
    },
    assistant: {
        inbox: "edit",
        messageRules: "edit",
        pricing: "edit",
        availability: "edit",
        accounts: "edit",
        listings: "edit",
        listingGroups: "edit",
        reservationFull: "edit",
        locks: "edit"
    },
    owner: {
        pricing: "view",
        availability: "edit",
        listingGroups: "view",
        reservationFinancial: "view"
    },
    cleaner: {
        listingGroups: "view",
        reservationBasic: "view"
    }
};

export const getRoleByPermissions = (permissions: PermissionsUser): Role | undefined => {
    const roleFound = Object.keys(mapRolePermissions).find(role =>
        isEqual(mapRolePermissions[role as Role], permissions)
    );
    return roleFound as Role | undefined;
};

// const mapPermissionLabel: Record<PermissionKey, string> = {
//     inbox: "Inbox",
//     messageRules: "Message Rules",
//     pricing: "Pricing",
//     availability: "Availability",
//     accounts: "Accounts",
//     listings: "Listings",
//     listingGroups: "Listing Groups",
//     reservationBasic: "Reservation Basic",
//     reservationFinancial: "Reservation Financial",
//     reservationFull: "Reservation Full",
//     settings: "Settings",
//     billing: "Billing",
//     userManagement: "User Management"
// };

export type Permission = [PermissionKey, PermissionValue] | [];

export type Permissions = {
    [Property in PermissionKey]?: {
        edit?: true;
        view?: true;
    };
};

export function hasPermissionToUser(
    permission: Permission,
    user: LeanDocument<UserDocument>
): boolean {
    const {originUserID, permissions} = user;

    // Master user
    if (!originUserID) {
        return true;
    }

    // No need permission to authorize
    if (permission.length === 0) {
        return true;
    }

    // User has no any permissions
    if (!permissions) {
        return false;
    }

    const permissionChecked = permission;
    const permissionKey = permissionChecked[0];
    const permissionValue = permissionChecked[1];
    const permissionsConverted = convertPermissions(permissions, originUserID.toString());

    return !!permissionsConverted[permissionKey]?.[permissionValue];
}

export function convertPermissions(
    permissions: UserDocument["permissions"] | undefined,
    originUserID?: string
): Permissions {
    if (!originUserID) {
        return {
            inbox: {edit: true, view: true},
            messageRules: {edit: true, view: true},
            pricing: {edit: true, view: true},
            availability: {edit: true, view: true},
            accounts: {edit: true, view: true},
            listings: {
                edit: true,
                view: true
            },
            listingGroups: {edit: true, view: true},
            reservationBasic: {edit: true, view: true},
            reservationFinancial: {edit: true, view: true},
            reservationFull: {edit: true, view: true},
            settings: {edit: true, view: true},
            billing: {edit: true, view: true},
            userManagement: {edit: true, view: true},
            locks: {edit: true, view: true}
        };
    }

    if (!permissions) {
        return {};
    }

    const permissionsConverted = Object.keys(permissions).reduce(
        (result: Permissions, key: string) => {
            return {
                ...result,
                [key]: {
                    edit: permissions[key as PermissionKey] === "edit",
                    view:
                        permissions[key as PermissionKey] === "edit" ||
                        permissions[key as PermissionKey] === "view"
                }
            };
        },
        {}
    );
    return permissionsConverted;
}
